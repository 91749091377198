import { getTelemetryDataFrom } from './TelemetryDataFactory';
import NAVIGATION_TIMEOUT from './telemetryConfig';

/**
 * @typedef {Function} TelemetryBuffer
 * @see TelemetryBuffer
 * @param {Function} handleEvent - a function to call when an event is
 *  detected. This should be provided by the TelemetryBuffer instance.
 */

/**
 * Given a target element finds the first "clickable" parent element of the given type
 *
 * @private
 * @param {string} type - element type (e.g. 'button', 'a')
 * @param {HTMLElement} target - the exact element that was clicked
 * @return {HTMLElement | false} return false if not found
 */
const getClickableElementOfType = (type, target) => {
  if (!target || target === document.body) return false;

  if (target.tagName === type.toUpperCase()) return target;

  return getClickableElementOfType(type, target.parentElement);
};

/**
 * Determine if a clicked link represents an exit click
 *
 * @private
 * @param {HTMLElement} link - a link element
 * @return {HTMLElement | false} return false if not found
 */
const isExitClick = (link) => {
  const linkTarget = link.target;
  const isSameOrigin = link.origin === window.location.origin;

  if (isSameOrigin) return false;

  return !linkTarget
    || linkTarget === '_self'
    || (linkTarget === '_parent' && window === window.parent)
    || (linkTarget === '_top' && window === window.parent)
    || linkTarget !== '_blank';
};

/**
 * A template function for capturing a click
 *
 * @private
 * @param {Object} param
 * @param {string} param.tagName - the tagName to create the function for (e.g. 'button', 'a')
 * @param {function} param.handleEvent - the method to call if the event can be handled
 * @return {Function} function returns false when event is handled
 */
const captureClick = ({ tagName, handleClick, scope }) => (e) => {
  const { target } = e;
  const el = getClickableElementOfType(tagName, target);

  if (!el) return target;

  const telemetryData = getTelemetryDataFrom(el);

  if (!telemetryData) return target;

  if (telemetryData.scope && scope !== telemetryData.scope) return false;

  handleClick({ el, e, telemetryData });

  return false;
};

/**
 * @typedef {Function} handleClickStrategy
 * @param {HTMLElement} param.el - the first clickable element either "button" or "a"
 * @param {TelemetryEvent} param.telemetryData - the telemetry data associated with
 *  the clickable element
 * @param {Object} param.e - the DOM click event
 */

/**
 * Strategy for handling clicks on links
 *
 * @private
 * @param {TelemetryFacade} telemetry - to capture if the event can be handled
 * @return {handleClickStrategy}
 */
const handleLinkClick = telemetry => ({ el, telemetryData, e }) => {
  const exitClick = isExitClick(el);
  const goToLink = () => { window.open(el.href, el.target || '_self'); };
  const { isLinkHandled } = telemetryData;

  e.preventDefault();

  telemetry.capture({
    ...telemetryData,
    payload: {
      destinationUrl: el.href,
      exitClick,
      ...telemetryData.payload,
    },
    afterSent: !isLinkHandled && exitClick && goToLink,
  });

  if (isLinkHandled) return;

  if (!exitClick) {
    goToLink();
  } else {
    setTimeout(goToLink, NAVIGATION_TIMEOUT);
  }
};

/**
 * Strategy for handling clicks on buttons
 *
 * @private
 * @param {TelemetryFacade} telemetry - to capture if the event can be handled
 * @return {handleClickStrategy}
 */
const handleButtonClick = telemetry => ({ telemetryData }) => telemetry.capture(telemetryData);

/**
 * A function which creates a click tracker given a configuration
 *
 * @param {Object} config
 * @param {Array.<string>} config.scopes - tells whether or not the event should be captured
 *  only captures events matching the given scope
 * @return {Function} to begin tracking clicks
 * @example
 * const stop = trackClicks({ scope: 'foo' })({ capture: (e) => console.log(e) });
 *
 * stop();
 */
export default ({ scope }) => (telemetry) => {
  const onClick = (e) => {
    const captureFns = [
      captureClick({ tagName: 'button', handleClick: handleButtonClick(telemetry), scope }),
      captureClick({ tagName: 'a', handleClick: handleLinkClick(telemetry), scope }),
    ];

    for (let i = 0; i < captureFns.length; i += 1) {
      const result = captureFns[i](e);

      if (result === false) return;
    }
  };

  document.addEventListener('click', onClick);

  return () => document.removeEventListener('click', onClick);
};
