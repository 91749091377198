const bem = (block, element, modifier) => {
  if (!element && !modifier) {
    return block;
  }

  let str = block;
  if (element) {
    str += `__${element}`;
  }

  // modifier also needs the preceeding block/element
  if (modifier) {
    str = `${str} ${str}--${modifier}`;
  }

  return str;
};

export default block => (element, modifier) => bem(block, element, modifier);
