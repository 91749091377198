import getQueryParam from '../getQueryParam';

const chineseLocaleMappings = {
  'chs-hk': 'zh-hans-hk',
  'cht-hk': 'zh-hant-hk',
  'cht-tw': 'zh-hant-tw',
};

const normalizeLocale = locale => chineseLocaleMappings[locale] || locale;

/**
 * Inspects the window context to identify the appropriate locale.
 * @param {Window} wnd
 * @returns {string} locale code (eg. en-us)
 */
export default (wnd = window) => {
  const { navigator, PDC = { Locale: {} } } = wnd;
  return normalizeLocale((
    getQueryParam(wnd.location.search, 'locale')
      || (PDC.Locale && PDC.Locale.getLocale && PDC.Locale.getLocale()[0])
      || navigator.language
      || 'unknown'
  ).toLowerCase());
};
