export default function (telemetry) {
  const sharedNavContainer = document.getElementById('shared-nav');
  const quickLinks = [].slice.call(sharedNavContainer.querySelectorAll('.shared-nav__link-list-anchor'));

  quickLinks.forEach((quickLink) => {
    let category = '';
    const linkName = `quicklinks-${quickLink.id.slice(quickLink.id.length - 1)}`;
    const stringIdMarker = 'msg_';
    const parentEl = quickLink.closest('.shared-nav__secondary-parent');

    parentEl.classList.forEach((className) => {
      const markerIndex = className.indexOf(stringIdMarker);
      if (markerIndex !== -1) {
        category = className.slice(markerIndex + stringIdMarker.length);
      }
    });

    const data = {
      category,
      linkName,
    };

    quickLink.setAttribute('data-telemetry', telemetry.makeEvent({ type: 'shared-nav:click:nav-item', data }));
  });
}
