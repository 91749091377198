
/**
 * Converts an HTML string into a DOM element.
 * Note: the string is expected to contain a single element
 * @param {string} str - String to convert into DOM
 * @return {Object} - DOM representation of the string
 */
export default (str, document) => {
  const div = document.createElement('div');
  div.innerHTML = (str || '').trim();
  return div.firstChild;
};
