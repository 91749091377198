const POLL_INTERVAL = 100;
// Give up waiting on Oracle hook after 10 secs
const TIMEOUT = 10000;

export const oracleIsSignedInHookIsReady = () => window
  && window.psnWebToolbar
  && typeof window.psnWebToolbar.isSignedIn === 'function';

export default () => new Promise((resolve) => {
  const current = new Date();
  const endTime = new Date(current.getTime() + TIMEOUT);
  // eslint-disable-next-line no-unused-vars
  let timer = null;

  const poll = () => {
    if (oracleIsSignedInHookIsReady()) {
      clearTimeout(timer);
      timer = null;

      resolve(window.psnWebToolbar.isSignedIn());
    } else {
      const currentTime = new Date().getTime();

      if (currentTime <= endTime) {
        timer = setTimeout(poll, POLL_INTERVAL);
      } else {
        resolve(false);
      }
    }
  };

  return poll();
});
