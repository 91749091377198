export default class GlobalKeydownHandler {
  _keydownHandlers = {};

  _handleKeydownEvent = (event) => {
    const { keyCode } = event;

    if (keyCode && this._keydownHandlers[keyCode]) {
      this._keydownHandlers[keyCode].forEach((keydownHandler) => {
        if (typeof keydownHandler === 'function') {
          keydownHandler(event);
        }
      });
    }
  }

  init() {
    document.body.addEventListener('keydown', this._handleKeydownEvent);
  }

  destroy() {
    this._keydownHandlers = {};
    document.body.removeEventListener('keydown', this._handleKeydownEvent);
  }

  addKeydownHandler(keyCode, keycodeHandler) {
    if (!this._keydownHandlers[keyCode]) {
      this._keydownHandlers[keyCode] = [];
    }
    this._keydownHandlers[keyCode].push(keycodeHandler);
  }
}

let keydownHandlerInstance = null;

export const getGlobalKeydownHandler = () => {
  if (keydownHandlerInstance) return keydownHandlerInstance;

  keydownHandlerInstance = new GlobalKeydownHandler();
  keydownHandlerInstance.init();

  return keydownHandlerInstance;
};
