import lines from './lines';
import sanitizeSearchTerm from './sanitizeSearchTerm';
import getTumblrSearchLocale from './getTumblerLocale';

const searchEndpoint = (searchTerm, smcid) => ((process.env.SEARCHV2_ENABLED === 'true')
  ? `/search/${searchTerm}?smcid=${encodeURIComponent(smcid)}`
  : `/grid/search-game?query=${searchTerm}&smcid=${encodeURIComponent(smcid)}`);

/**
 * Constructs a tumbler search url based on the provided parameters
 * @param {object} param0
 * @param {string} param0.language - PSN language code (e.g. en, es, ja)
 * @param {string} param0.country - PSN country code (e.g. us, mx, jp)
 * @param {string} param0.line - PSN line (e.g. e1-np, np)
 * @param {string} param0.searchTerm - Search Term
 * @param {string} param0.smcid - the tracking identifier
 * @returns {string} - tumbler search url
 */
const buildSeeAllUrl = ({
  language = '', script, country = '', line = 'e1-np', searchTerm = '', smcid = '',
}) => {
  const baseUrl = lines[line];
  if (!baseUrl) {
    throw new Error(
      `nav/search/tumbler/buildSeeAllUrl: the specified line "${line}" does not exist. `
      + `avilable lines are "${Object.keys(lines).join(',')}"`,
    );
  }

  const sanitizedSearchTerm = sanitizeSearchTerm(searchTerm);

  if (!language) { throw new Error('tumbler::buildSeeAllUrl - language is required'); }
  if (!country) { throw new Error('tumbler::buildSeeAllUrl - country is required'); }
  if (!sanitizedSearchTerm) { throw new Error('tumbler::buildSeeAllUrl - search is required'); }

  const {
    country: storeCountry,
    language: storeLanguage,
  } = getTumblrSearchLocale(language, country);
  const locale = `${storeLanguage.toLowerCase()}${script ? `-${script}` : ''}-${storeCountry.toLowerCase()}`;

  return `${baseUrl}/${locale}${searchEndpoint(sanitizedSearchTerm, smcid)}`;
};

export default buildSeeAllUrl;
