/**
 * This file contains mappings for Store locales which do not exist in PDC.
 * Store locales will use theIA files for associated PDC locale. eg,
 * the en-BH store will use en-AE's IA and link to that PDC.
 */

export default {
  'ar-ae': 'ar-ae',
  'ar-bh': 'ar-sa',
  'ar-kw': 'ar-sa',
  'ar-lb': 'ar-sa',
  'ar-om': 'ar-sa',
  'ar-qa': 'ar-sa',
  'en-bh': 'en-ae',
  'en-bg': 'en-bg',
  'en-cy': 'en-cy',
  'en-cz': 'en-cz',
  'en-dk': 'en-dk',
  'en-fi': 'fi-fi',
  'en-gr': 'el-gr',
  'en-hr': 'en-hr',
  'en-hu': 'en-hu',
  'en-il': 'en-il',
  'en-is': 'en-is',
  'en-kw': 'en-ae',
  'en-lb': 'en-ae',
  'en-mt': 'en-mt',
  'en-no': 'en-no',
  'en-om': 'en-ae',
  'en-pl': 'en-pl',
  'en-qa': 'en-ae',
  'en-ro': 'en-ro',
  'en-sa': 'en-ae',
  'en-sk': 'en-sk',
  'en-se': 'en-se',
  'en-si': 'en-si',
  'en-tr': 'en-tr',
  'ru-ua': 'ru-ru',
};
