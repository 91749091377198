import regionEurope from '@sie/shared-nav-ia-region-europe';
import regionAmericas from '@sie/shared-nav-ia-region-americas';
import regionJapan from '@sie/shared-nav-ia-region-japan';
import regionAsia from '@sie/shared-nav-ia-region-asia';

export const SIE_JAPAN = 'SIE_JAPAN';
export const SIE_ASIA = 'SIE_ASIA';
export const SIE_EUROPE = 'SIE_EUROPE';
export const SIE_AMERICAS = 'SIE_AMERICAS';

const includes = (obj, value) => Object.keys(obj)
  .findIndex(i => i.toLowerCase() === value.toLowerCase()) !== -1;

const regionMap = {
  [SIE_JAPAN]: locale => includes(regionJapan, locale),
  [SIE_ASIA]: locale => includes(regionAsia, locale),
  [SIE_EUROPE]: locale => includes(regionEurope, locale),
  [SIE_AMERICAS]: locale => includes(regionAmericas, locale),
};

export default (locale) => {
  if (typeof locale !== 'string') { return ''; }
  return Object.keys(regionMap)
    .reduce((prev, region) => {
      if (prev) return prev;
      return regionMap[region](locale) ? region : '';
    }, '');
};
