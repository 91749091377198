import getTransitionEndName from './getTransitionEndEventName';

/**
 * Operations to support animation on closing L2 nav by clicking outside shared nav
 * or toggle the active L1 element. We need this because L2 (.shared-nav__secondary-parent)
 * needs to be hidden in closed state in order for keyboard tab accessibility to work
 * properly. However, a hidden element won't perform any animation, nor does display none.
 * As a result, we're adding an intermediate class `.closing-sub-nav` to make it visible
 * and remove it once the `max-height` animation completes, when L2 completely collapsed.
 *
 * @param {DOMObject} targetParent
 */
export function closingSubNav(targetParent) {
  if (!targetParent) { return; }

  const secondaryParent = targetParent.querySelector('.shared-nav__secondary-parent');
  const transitionEndName = getTransitionEndName(targetParent.ownerDocument);

  targetParent.classList.add('closing-sub-nav');

  const handler = (e) => {
    const isExpectedTransition = e.propertyName === 'max-height' || e.propertyName === 'left';

    if (!(e.target === secondaryParent && isExpectedTransition)) { return; }

    targetParent.classList.remove('closing-sub-nav');
    secondaryParent.removeEventListener(transitionEndName, handler);
  };

  secondaryParent.addEventListener(transitionEndName, handler, false);
}

/**
 * Similar to the `closingSubNav()` method above, this method supports animation on switching
 * between L1. It adds the `.switching-between-tabs` class to the old and new L2 parent
 * in order to provide proper transition during this state. These classes are removed
 * once the `opacity` transition ends on the new secondary item.
 *
 * @param {DOMObject} oldSecondaryParent
 * @param {DOMObject} newSecondaryParent
 */
export function switchingBetweenTabs(oldSecondaryParent, newSecondaryParent) {
  if (!oldSecondaryParent || !newSecondaryParent) { return; }

  const newSecondaryItem = newSecondaryParent.querySelector('.shared-nav__secondary-item');
  const transitionEndName = getTransitionEndName(oldSecondaryParent.ownerDocument);

  const handler = (e) => {
    if (e.target !== newSecondaryItem || e.propertyName !== 'opacity') { return; }

    oldSecondaryParent.classList.remove('switching-between-tabs');
    newSecondaryParent.classList.remove('switching-between-tabs');
    newSecondaryItem.removeEventListener(transitionEndName, handler, false);
  };

  newSecondaryParent.classList.add('switching-between-tabs');
  oldSecondaryParent.classList.add('switching-between-tabs');
  newSecondaryItem.addEventListener(transitionEndName, handler, false);
}
