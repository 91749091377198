export const queryStringToMap = queryString => (/^\?/.test(queryString) ? queryString.substr(1) : queryString)
  .split('&')
  .reduce((prev, cur) => {
    const [key, val] = cur.split('=');
    return { ...prev, [key]: decodeURIComponent(val) };
  }, {});

/**
 * Extracts a given parameter from a search string
 *
 * @param {string} queryString - the query string to parse
 * @param {string} param - the parameter from the query string to extract
 * @return {string} - query string parameter value. defaults to empty string
 *                    if the param was not found or was falsey.
 */
export default (queryString, param) => {
  if (!param) { return ''; }

  return queryStringToMap(queryString)[param] || '';
};
