import whiteListedDomainToLineMap from './whitelist';
import TOOLBAR from '../constants/toolbar';
import generateNonce from './generateNonce';

const getOracleBootstrapSrc = (line) => {
  const nonce = generateNonce();

  switch (line) {
    case 'e1-np':
    case 'mgmt':
      return `https://web-toolbar.${line}.playstation.com/master/psnBootstrap.js?nonce=${nonce}`;
    case 'np':
      return `https://web-toolbar.playstation.com/psnBootstrap.js?nonce=${nonce}`;
    default:
      return null;
  }
};

const getStarblasterBootstrapSrc = (line) => {
  switch (line) {
    case 'e1-np':
      return 'https://social.e1-np.api.playstation.com/starblaster/pdc/master/assets/bootstrap.js';
    case 'mgmt':
    case 'sp-int':
      return `https://social.${line}.playstation.com/starblaster/pdc/master/assets/bootstrap.js`;
    case 'np':
      return 'https://social.playstation.com/starblaster/pdc/master/assets/bootstrap.js';
    default:
      return null;
  }
};

export default (hostname, webToolbarType) => {
  const line = whiteListedDomainToLineMap[hostname] || 'e1-np';

  let bootstrapSrc;
  let scriptId;
  const script = {};
  const {
    name: oracleName,
    scriptId: oracleScriptId,
  } = TOOLBAR.ORACLE;
  const {
    name: starblasterName,
    scriptId: starblasterScriptId,
  } = TOOLBAR.STARBLASTER;

  if (webToolbarType === oracleName) {
    bootstrapSrc = getOracleBootstrapSrc(line);
    scriptId = oracleScriptId;
  } else if (webToolbarType === starblasterName) {
    bootstrapSrc = getStarblasterBootstrapSrc(line);
    scriptId = starblasterScriptId;
  }

  if (bootstrapSrc) {
    script.id = scriptId;
    script.src = bootstrapSrc;
    script.crossorigin = 'use-credentials';
  }

  return script;
};
