import debounce from 'debounce-promise';
import buildSeeAllUrl from './buildSeeAllUrl';
import executeSearch, { makeSMCID } from './executeSearch';

const SEARCH_DEBOUNCE_MS = 100;

/**
 * Tumbler is the legacy search API for store content.
 */
export default class TumblerSearch {
  /**
   * Last timestamp at which a search has been executed.
   * @private
   * @type {number}
   */
  #lastSearchTime = +(new Date());

  /**
   * Search configuration
   * @private
   * @type {Object}
   */
  #config = {};

  /**
   * @param {string} searchTerm - search string
   * @returns {Promise.<Object?>}
   */
  search = debounce(
    searchTerm => executeSearch(searchTerm, this.#config)
      .then((val) => {
        const isOutdated = this.#lastSearchTime > val.timestamp;
        this.#lastSearchTime = +(new Date());
        return isOutdated ? {} : val;
      }),
    SEARCH_DEBOUNCE_MS,
  );

  /**
   * Used to uniquely identify this search strategy
   * @type {string}
   * @static
   */
  static name = 'store';

  /**
   *
   * @param {Object} config - search configuration
   * @param {Function=} buildSeeAllUrl - buildSeeAllUrl implmentation
   */
  constructor(config) {
    const { onLinkClick } = config || {};

    this.#config = config;
    this.name = TumblerSearch.name;

    if (typeof onLinkClick === 'function') {
      this.onLinkClick = onLinkClick;
    }
  }

  /**
   * Returns a URL where the search results can be viewed in the parent application.
   * @param {string} searchTerm - the search string
   * @returns {string}
   */
  getUrl = searchTerm => buildSeeAllUrl({
    ...this.#config,
    searchTerm,
    smcid: makeSMCID({ searchTerm, location: this.#config.location }),
  });
}
