const whiteListedDomainToLineMap = {
  // Compete
  'compete.playstation.com': 'np',
  'compete-stage.sp-int.playstation.com': 'sp-int',
  'compete.sp-int.playstation.com': 'sp-int',
  // Web store
  'webstore.e1-np.playstation.com': 'e1-np',
  'webstore.mgmt.playstation.com': 'mgmt',
  'webstore.playstation.com': 'np',
  // Valkyrie NP store will point to webstore
  'store.playstation.com': 'np',
  // My PS
  'my.e1-np.playstation.com': 'e1-np',
  'my.mgmt.playstation.com': 'mgmt',
  'my.playstation.com': 'np',
  // Library
  'library.e1-np.playstation.com': 'e1-np',
  'library.mgmt.playstation.com': 'mgmt',
  'library.playstation.com': 'np',
  // stand-alone web-checkout
  'webcheckout.e1-np.playstation.com': 'e1-np',
  'webcheckout.mgmt.playstation.com': 'mgmt',
  'checkout.playstation.com': 'np',
  // PDC
  'author.stage.playstation.com': 'mgmt',
  'stage.playstation.com': 'mgmt',
  'author.playstation.com': 'np',
  'www.playstation.com': 'np',
  'preview.www.playstation.com': 'np',
  // Wrapup
  'qa.wrapup.playstation.com': 'e1-np',
  'dev.wrapup.playstation.com': 'e1-np',
  'www.wrapup.playstation.com': 'np',
  // Kiki
  'forms.playstation.com': 'np',
  'forms-preview.playstation.com': 'np',
  'www.jp.playstation.com': 'np',
  'asia.playstation.com': 'np',
  'asia.playstation.com.cn': 'np',
  'forms-stage.playstation.com': 'e1-np',
  'forms-qa.playstation.com': 'e1-np',
  'forms-dev.playstation.com': 'e1-np',
  'forms-preview-stage.playstation.com': 'e1-np',
  'forms-preview-qa.playstation.com': 'e1-np',
  'forms-preview-dev.playstation.com': 'e1-np',
  'qa.jp.playstation.com': 'e1-np',
  'dev-public.asia.playstation.com': 'e1-np',
  'dev-public.asia.playstation.com.cn': 'e1-np',
  'stg.jp.playstation.com': 'e1-np',
  'prod-check.asia.playstation.com': 'e1-np',
  'prod-check.asia.playstation.com.cn': 'e1-np',
};

export default whiteListedDomainToLineMap;
