export const pdcToTumblerLocale = {
  'bg-bg': {
    country: 'BG',
    language: 'en',
  },
};

export default (language, country) => {
  // Country code should be in capital letter
  const key = `${language.toLowerCase()}-${country.toLowerCase()}`;

  return pdcToTumblerLocale[key] || {
    country,
    language,
  };
};
