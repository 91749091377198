/**
 * Interface for a telemetry spec
 *
 * @interface TelemetrySpec
 */

/**
 * Type identifier for the spec, generally takes the format
 * <namespace>:<event-type>:<event-name> (i.e. 'search:click:search-result')
 * types should be all lowercase and dasherized
 *
 * @property
 * @name TelemetrySpec#type
 */

/**
 * Definitions are any static data associated with a spec. These are often
 * found in the telemetry specs and noted with angle brackets. An example is
 * <SORT_TERM> where it is noted in the spec as:
 *
 * Values for <SORT_TERM>:
 * - PlayStation.com
 * - PlayStation Store
 *
 * @property
 * @name TelemetrySpec#definitions
 */

/**
 * Makes the telemetry event of the given type
 *
 * @function
 * @name TelemetrySpec#make
 * @returns {TelemetryEvent}
 */

/**
 * Page view event for search feature
 * @implements TelemetrySpec
 * @see https://confluence.sie.sony.com/display/SOC/Requirements+for+Jetstream+Telemetry#RequirementsforJetstreamTelemetry-E2ACapturepagevieweventwhenuserselectssearchicon
 */
export default {
  type: 'page-view',
  /**
   * Creates the event representing a page view on search
   * @return {TelemetryEvent}
   */
  make(meta) {
    const { data = {} } = meta || {};
    const { marketingCampaign, referrer } = data;

    return {
      type: 'PageViewEvent',
      payload: {
        location: 'search',
        referrer,
        marketingCampaign,
      },
    };
  },
};
