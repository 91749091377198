import smoothscroll from 'smoothscroll-polyfill';
import debounce from 'debounce-promise';

export default ({ document }) => {
  const carouselItemsContainer = document.querySelector('.carousel-tertiary-tabs');

  // PDC pages might not have tertiary nav - Skip all the hard work :D
  if (!carouselItemsContainer) return;

  smoothscroll.polyfill();
  const nextButton = document.querySelector('.carousel__button--next');
  const prevButton = document.querySelector('.carousel__button--prev');
  const prevButtonOverlay = document.querySelector('.carousel__overlay--prev');
  const nextButtonOverlay = document.querySelector('.carousel__overlay--next');
  const tertiaryCarousels = document.querySelector('.tertiary-carousels');
  const tertiaryLogo = document.querySelector('.tertiary-logo');
  const tertiaryCtas = document.querySelector('.tertiary-ctas');
  const scrollValue = 150;

  const getCarouselItemsWidth = () => {
    const carouselItems = document.querySelectorAll('.tertiary-tab');

    let carouselItemsWidth = 0;

    carouselItems.forEach((carouselItem) => {
      carouselItemsWidth += carouselItem.offsetWidth;
    });

    return carouselItemsWidth;
  };

  const scrollCarousel = (leftPosition) => {
    carouselItemsContainer.scroll({
      behavior: 'smooth',
      left: leftPosition,
    });
  };

  const adjustCarouselPosition = () => {
    // If we have cta, then we don't need to tweak the positioning
    // of carousel and let it be positioned naturally
    if (tertiaryCtas && tertiaryCtas.offsetWidth) return;

    const viewportWidth = Math.max(document.documentElement.clientWidth || 0,
      window.innerWidth || 0);
    const carouselWidth = getCarouselItemsWidth();
    const logoWidth = tertiaryLogo.offsetWidth;
    const delta = viewportWidth - (2 * logoWidth) - carouselWidth;

    if (delta >= 0) {
      // We have enough space, so centerize the carousel
      tertiaryCarousels.classList.add('centered');
    } else {
      tertiaryCarousels.classList.remove('centered');
    }
  };

  const htmlElement = document.querySelector('html');
  const dir = htmlElement.getAttribute('dir');

  const adjustPrevButton = () => {
    const {
      scrollLeft,
    } = carouselItemsContainer;

    if ((scrollLeft > 0 && dir === 'ltr') || (dir === 'rtl' && scrollLeft < 0)) {
      prevButtonOverlay.classList.remove('hide');
      tertiaryCarousels.classList.add('show-prev');
    } else {
      prevButtonOverlay.classList.add('hide');
      tertiaryCarousels.classList.remove('show-prev');
    }
  };

  const adjustNextButton = () => {
    const {
      offsetWidth,
      scrollLeft,
      scrollWidth,
    } = carouselItemsContainer;

    const maxWidthForRTL = offsetWidth - scrollLeft - scrollWidth;
    const maxWidthForLtr = scrollWidth - scrollLeft - offsetWidth;
    const isRtl = dir === 'rtl';
    // It's possible to not get 0 since scrollLeft is float, so let's put a bit of offset
    const OFFSET = 1;

    if ((!isRtl && maxWidthForLtr <= OFFSET)
      || (isRtl && maxWidthForRTL >= -1 * OFFSET)) {
      // We have scrolled up to the max horizontal scroll - hide the next button
      nextButtonOverlay.classList.add('hide');
      tertiaryCarousels.classList.remove('show-next');
    } else {
      const containerWidth = carouselItemsContainer.clientWidth;
      const carouselItemsWidth = getCarouselItemsWidth();

      if (containerWidth > carouselItemsWidth) {
        nextButtonOverlay.classList.add('hide');
        tertiaryCarousels.classList.remove('show-next');
      } else {
        nextButtonOverlay.classList.remove('hide');
        tertiaryCarousels.classList.add('show-next');
      }
    }
  };

  const adjustActiveTabPosition = (activeTab) => {
    if (!activeTab) return;

    const tabParent = activeTab.parentElement;
    const tabParentBound = tabParent.getBoundingClientRect();
    const carouselBound = carouselItemsContainer.getBoundingClientRect();
    const {
      left: tabParentLeft,
      width: tabParentWidth,
    } = tabParentBound;
    const {
      left: carouselLeft,
      width: carouselWidth,
    } = carouselBound;
    const activeTabRelativeLeft = tabParentLeft - carouselLeft;
    const halfCarouselWidth = carouselWidth / 2;
    const delta = activeTabRelativeLeft + tabParentWidth / 2 - halfCarouselWidth;

    scrollCarousel(carouselItemsContainer.scrollLeft + delta);
  };

  const autoAdjustActiveTabPosition = () => {
    const activeTab = carouselItemsContainer.querySelector('.tertiary-tab__link--active');

    adjustActiveTabPosition(activeTab);
  };

  const adjustCarouselControls = () => {
    adjustCarouselPosition();
    adjustPrevButton();
    adjustNextButton();
    autoAdjustActiveTabPosition();
  };

  const onScroll = () => {
    adjustPrevButton();
    adjustNextButton();
  };

  const onNextNavClicked = () => {
    if (dir === 'ltr') {
      scrollCarousel(carouselItemsContainer.scrollLeft + scrollValue);
    } else {
      scrollCarousel(carouselItemsContainer.scrollLeft - scrollValue);
    }
  };

  const onPrevNavClicked = () => {
    if (dir === 'ltr') {
      scrollCarousel(carouselItemsContainer.scrollLeft - scrollValue);
    } else {
      scrollCarousel(carouselItemsContainer.scrollLeft + scrollValue);
    }
  };

  (() => {
    const maxAttempts = 5;
    let timer;
    let numOfAttempts = 0;

    const stopPolling = () => {
      clearTimeout(timer);
      timer = null;
    };

    const initHelper = () => {
      const {
        offsetWidth,
      } = carouselItemsContainer;

      if (!offsetWidth) {
        // We have exhausted number of attempts, give up and just start init
        if (numOfAttempts >= maxAttempts) {
          stopPolling();
          adjustCarouselControls();
        }
        numOfAttempts += 1;
        timer = setTimeout(initHelper, 100);
      } else {
        stopPolling();
        adjustCarouselControls();
      }
    };

    initHelper();
  })();

  /**
   * We use ResizeObserver since we don't want to listen to resize
   * event of window as scrolling the window will trigger resize
   * event for mobile devices. Listening to resize event of carousel
   * container is more reliable way
   * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
   */
  const debouncedResize = debounce(adjustCarouselControls, 100, { leading: true });
  const resizeObserver = new ResizeObserver(debouncedResize);
  resizeObserver.observe(tertiaryCarousels);

  const debounceScroll = debounce(onScroll, 250, { leading: true });
  carouselItemsContainer.addEventListener('scroll', debounceScroll);

  nextButton.addEventListener('click', onNextNavClicked);
  /**
   * The overlay needs to be clickable but it doesn't make sense
   * to make overlay as button, thus this anti-a11y practice :(
   * But since we have accessible prev/next button as well, this
   * is not too bad :P
   */
  nextButtonOverlay.addEventListener('click', onNextNavClicked);
  prevButton.addEventListener('click', onPrevNavClicked);
  prevButtonOverlay.addEventListener('click', onPrevNavClicked);

  carouselItemsContainer.addEventListener('click', (evt) => {
    if (evt && evt.target && evt.target.classList.contains('tertiary-tab__link')) {
      const currentActiveTab = carouselItemsContainer.querySelector('.tertiary-tab__link.tertiary-tab__link--active');

      if (currentActiveTab) {
        currentActiveTab.classList.remove('tertiary-tab__link--active');
      }
      evt.target.classList.add('tertiary-tab__link--active');
      adjustActiveTabPosition(evt.target);
    }

    return true;
  });
};
