export default (document) => {
  const el = document.createElement('div');
  const transitions = {
    transition: 'transitionend',
    OTransition: 'otransitionend',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  let transitionEndName = 'transitionend';

  Object.keys(transitions).forEach((key) => {
    if (el.style[key] !== undefined) {
      transitionEndName = transitions[key];
    }
  });

  return transitionEndName;
};
