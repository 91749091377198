import { h, render } from 'preact';
import SearchFactory from './services/SearchFactory';
import SearchScreen, { DEFAULT_SEARCH_SOURCE } from './containers/SearchScreen';
import ScreenReaderFocuser from '../utils/ScreenReaderFocuser';
import createElementFromHTML from '../utils/createElementFromHTML';
import searchTelemetrySpecs from '../utils/telemetry/specs/search';

/**
 * Kamaji constant for unknown age
 */
const STORE_SEARCH_AGE_UNKNOWN = 99;

export default class Search {
  /**
   * The HtmlElement which triggered the search
   * @type {Element}
   */
  #returnEl = null;

  /**
   * Localization service
   * @type {Function}
   */
  #l10n;

  /**
   * Responsible for instantiating the search system
   * @type {SearchFactory}
   */
  #searchFactory;

  #telemetry;

  #screenReader;

  #searchEl;

  #rootEl;

  #searchSource;

  #showSearchContextSelector;

  /**
   * @param {Object} param0
   * @param {Element} param0.returnEl - Element which should be focused after
   *                                    closing the search module
   * @param {string} param0.language - User's language (should be derived from PSN
   *                                   account information when possible)
   * @param {string} param0.country - User's country (should be derived from PSN account
   *                                  information when possible)
   * @param {string} param0.line - PSN integration line (eg. e1-np, np, etc)
   * @param {string} param0.searchSource - The search context - currently either PDC or Store
   * @param {Function} param0.l10n - localizer
   * @param {TelemetryFacade} param0.telemetry - the applications telemetry instance
   * @param {boolean} param0.showSearchContextSelector - Boolean indicating if the search
   *                                                     selector should be visible. In some
   *                                                     PDC locales, marketing would
   *                                                     only like store search available.
   */
  constructor({
    returnEl,
    l10n,
    telemetry,
    onStoreLinkClick,
    language = 'en',
    country = 'us',
    script,
    age = STORE_SEARCH_AGE_UNKNOWN,
    line = 'e1-np',
    searchSource = DEFAULT_SEARCH_SOURCE,
    showSearchContextSelector = true,
  }) {
    if (typeof l10n !== 'function') {
      throw new Error('Search.constructor: l10n must be a function');
    }

    this.#telemetry = telemetry;
    this.#returnEl = returnEl;
    this.#l10n = l10n;
    this.#searchSource = searchSource;
    this.#showSearchContextSelector = showSearchContextSelector;

    this.#screenReader = new ScreenReaderFocuser();
    this.#searchFactory = new SearchFactory({
      location: telemetry.getLocation('search'),
      language,
      country,
      script,
      age,
      line,
    });

    this.#searchFactory.setOnLinkClick('store', onStoreLinkClick);
  }

  setup() {
    if (this.#rootEl) return;

    this.#telemetry.addSpec('search', searchTelemetrySpecs);
    this.#telemetry.addLocator('search', key => !!document.querySelector('.jetstream-search') && key);
    this.#rootEl = createElementFromHTML('<div class="search-container"></div>', document);

    document.body.appendChild(this.#rootEl);
  }

  unmount() {
    if (!this.#searchEl) return;

    render(null, this.#rootEl, this.#searchEl);
    this.#searchEl = null;
  }

  mount() {
    if (this.#searchEl) return;

    this.#searchEl = render((
      <SearchScreen
        searchFactory={this.#searchFactory}
        l10n={this.#l10n}
        telemetry={this.#telemetry}
        searchSource={this.#searchSource}
        showSearchContextSelector={this.#showSearchContextSelector}
        onSearchClose={() => {
          this.#returnEl.focus();
          this.unmount();
          this.#screenReader.unfocus(this.#rootEl);
        }}
      />
    ), this.#rootEl);
    this.#screenReader.focus(this.#rootEl);
  }
}
