/* eslint-disable global-require */

/**
 * This is a compact mapping of the data found in starblaster's locale data, specifically for gensen
 * @see https://github.sie.sony.com/SIE/starblaster/blob/master/app/config/sections/locale.js
 */
export default {
  'ar-ae': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'ar-bh': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'ar-kw': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'ar-lb': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'ar-om': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'ar-qa': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'ar-sa': require('@sie/shared-nav-gensen-search/dist/ar-ae.json'),
  'bg-bg': require('@sie/shared-nav-gensen-search/dist/bg-bg.json'),
  'cs-cz': require('@sie/shared-nav-gensen-search/dist/cs-cz.json'),
  'da-dk': require('@sie/shared-nav-gensen-search/dist/da-dk.json'),
  'de-at': require('@sie/shared-nav-gensen-search/dist/de-de.json'),
  'de-ch': require('@sie/shared-nav-gensen-search/dist/de-de.json'),
  'de-de': require('@sie/shared-nav-gensen-search/dist/de-de.json'),
  'de-lu': require('@sie/shared-nav-gensen-search/dist/de-de.json'),
  'el-gr': require('@sie/shared-nav-gensen-search/dist/el-gr.json'),
  'en-ae': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-au': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-bg': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-bh': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-ca': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-cy': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-cz': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-dk': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-fi': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-gb': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-gr': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-hk': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-hr': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-hu': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-id': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-ie': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-il': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-in': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-is': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-kw': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-lb': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-mt': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-my': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-no': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-nz': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-om': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-ph': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-pl': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-qa': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-ro': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-sa': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-se': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-sg': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-si': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-sk': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-th': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-tr': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-tw': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-us': require('@sie/shared-nav-gensen-search/dist/en-us.json'),
  'en-vn': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'en-za': require('@sie/shared-nav-gensen-search/dist/en-gb.json'),
  'es-ar': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-bo': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-cl': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-co': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-cr': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-ec': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-es': require('@sie/shared-nav-gensen-search/dist/es-es.json'),
  'es-gt': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-hn': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-mx': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-ni': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-pa': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-pe': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-py': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-sv': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-uy': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'es-ve': require('@sie/shared-nav-gensen-search/dist/es-419.json'),
  'fi-fi': require('@sie/shared-nav-gensen-search/dist/fi-fi.json'),
  'fr-be': require('@sie/shared-nav-gensen-search/dist/fr-fr.json'),
  'fr-ca': require('@sie/shared-nav-gensen-search/dist/fr-ca.json'),
  'fr-ch': require('@sie/shared-nav-gensen-search/dist/fr-fr.json'),
  'fr-fr': require('@sie/shared-nav-gensen-search/dist/fr-fr.json'),
  'fr-lu': require('@sie/shared-nav-gensen-search/dist/fr-fr.json'),
  'he-il': require('@sie/shared-nav-gensen-search/dist/he-il.json'),
  'hr-hr': require('@sie/shared-nav-gensen-search/dist/hr-hr.json'),
  'hu-hu': require('@sie/shared-nav-gensen-search/dist/hu-hu.json'),
  'iw-il': require('@sie/shared-nav-gensen-search/dist/he-il.json'),
  'it-ch': require('@sie/shared-nav-gensen-search/dist/it-it.json'),
  'it-it': require('@sie/shared-nav-gensen-search/dist/it-it.json'),
  'ja-jp': require('@sie/shared-nav-gensen-search/dist/ja-jp.json'),
  'ko-kr': require('@sie/shared-nav-gensen-search/dist/ko-kr.json'),
  'nl-be': require('@sie/shared-nav-gensen-search/dist/nl-nl.json'),
  'nl-nl': require('@sie/shared-nav-gensen-search/dist/nl-nl.json'),
  'no-no': require('@sie/shared-nav-gensen-search/dist/no-no.json'),
  'pl-pl': require('@sie/shared-nav-gensen-search/dist/pl-pl.json'),
  'pt-br': require('@sie/shared-nav-gensen-search/dist/pt-br.json'),
  'pt-pt': require('@sie/shared-nav-gensen-search/dist/pt-pt.json'),
  'ro-ro': require('@sie/shared-nav-gensen-search/dist/ro-ro.json'),
  'ru-ru': require('@sie/shared-nav-gensen-search/dist/ru-ru.json'),
  'ru-ua': require('@sie/shared-nav-gensen-search/dist/ru-ru.json'),
  'sk-sk': require('@sie/shared-nav-gensen-search/dist/sk-sk.json'),
  'sl-si': require('@sie/shared-nav-gensen-search/dist/sl-si.json'),
  'sr-rs': require('@sie/shared-nav-gensen-search/dist/sr-rs.json'),
  'sv-se': require('@sie/shared-nav-gensen-search/dist/sv-se.json'),
  'th-th': require('@sie/shared-nav-gensen-search/dist/th-th.json'),
  'tr-tr': require('@sie/shared-nav-gensen-search/dist/tr-tr.json'),
  'uk-ua': require('@sie/shared-nav-gensen-search/dist/uk-ua.json'),
  'zh-hans-cn': require('@sie/shared-nav-gensen-search/dist/zh-hans.json'),
  'zh-hans-hk': require('@sie/shared-nav-gensen-search/dist/zh-hans.json'),
  'zh-hant-hk': require('@sie/shared-nav-gensen-search/dist/zh-hant.json'),
  'zh-hant-tw': require('@sie/shared-nav-gensen-search/dist/zh-hant.json'),
  // zz-ZZ is a special case which simply echoes out string keys
  'zz-zz': Object.keys(require('@sie/shared-nav-gensen-search/dist/en-us.json'))
    .reduce((prev, cur) => ({ ...prev, [cur]: cur }), {}),

  // pdc-specific locales
  'chs-cn': require('@sie/shared-nav-gensen-search/dist/zh-hans.json'),
  'chs-hk': require('@sie/shared-nav-gensen-search/dist/zh-hans.json'),
  'cht-hk': require('@sie/shared-nav-gensen-search/dist/zh-hant.json'),
  'cht-tw': require('@sie/shared-nav-gensen-search/dist/zh-hant.json'),
};
