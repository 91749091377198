import { h, Component } from 'preact';
import bemNamespace from '../../../../utils/bemNamespace';

export const bem = bemNamespace('thumbnail-image');

/**
 * @param {string} src - src image
 * @param {number} width - desired image width
 * @returns {string} formatted kamaji container api image url
 */
const createSrc = (src, width) => `${src}?w=${width}`;

/**
 * Creates a proper srcset for a given image and desired width
 * @param {string} src - src image
 * @param {number} width - desired image width
 * @returns {string} formatted kamaji container api image url
 */
const createSrcSet = (img, width) => [2, 3, 4]
  .map(n => `${createSrc(img, width * n)} ${n}x`)
  .join(', ');

/**
 * Smart image component which shows a product thumbnail.
 * While the image is loading, a PlayStation store icon is present.
 */
export default class ThumbnailImage extends Component {
  // eslint-disable-next-line react/no-unused-state
  state = { isImageLoaded: false };

  /**
   * Attempts to load the img within the DOM,
   * and if successful notifies the component to display it.
   * @returns void
   */
  componentDidMount() {
    const { src, width } = this.props;

    const tmpImg = new Image();
    tmpImg.src = createSrc(src, width);
    tmpImg.srcset = createSrcSet(src, width);

    tmpImg.onload = () => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ isImageLoaded: true });
    };
  }

  /**
   * Component
   * @param {object} props - component properties
   * @param {string} props.src - src image url - expects this to be container image url.
   *                             this image will be appended with an kamaji
   *                             image api width parameter.
   * @param {number} props.width - the desired width of the image. aspect ratio will be maintained.
   * @param {object} state - component state
   * @param {boolean} state.isImageLoaded - boolean indicated if the src image
   *                                        was loaded successfully
   * @returns {JSX.Element}
   */
  render(props, state) {
    const { width = 0, src = '', alt = '' } = props;
    const { isImageLoaded } = state;
    return (
      <div className={bem()}>
        {
          isImageLoaded && (
          <img
            alt={alt}
            className={bem('img', 'fade-in')}
            src={createSrc(src, width)}
            srcSet={createSrcSet(src, width)}
          />
          )
        }
        { !isImageLoaded && <div className={bem('preload')} /> }
      </div>
    );
  }
}
