import normalizeAge from './normalizeAge';
import sanitizeSearchTerm from './sanitizeSearchTerm';
import buildTumblerUrl from './buildTumblerUrl';
import buildStoreResolveUrl from './buildStoreResolveUrl';
import buildSeeAllUrl from './buildSeeAllUrl';

/**
 * Executes a tumbler search based on the provided parameters
 * @param {object} param0
 * @param {string} param0.language - PSN language code (e.g. en, es, ja)
 * @param {string} param0.country - PSN country code (e.g. us, mx, jp)
 * @param {string} param0.line - PSN line (e.g. e1-np, np)
 * @param {string} param0.search - Search Term
 * @param {string} param0.age - User's age
 * @param {string} param0.smcid - the tracking identifier
 * @returns {Promise.<object>}
 */

export const makeSMCID = ({
  location,
  searchTerm,
  productName = '',
  position = '',
}) => `${location}:${sanitizeSearchTerm(searchTerm)}:${productName}:${position}`.replace(/:+$/, '');

export default (searchTerm, {
  language, script, country, line, age, location,
}) => {
  const tumblerLanguage = (script === 'hant') ? 'ch' : language;

  const url = buildTumblerUrl({
    language: tumblerLanguage, country, line, searchTerm, age: normalizeAge(age),
  });

  return fetch(url)
    .then(response => response.json())
    .then(data => ({
      timestamp: +(new Date()),
      seeAllUrl: buildSeeAllUrl({
        searchTerm,
        language,
        script,
        country,
        line,
        smcid: makeSMCID({ location, searchTerm, productName: 'see all' }),
      }),
      results: ((data || {}).links || [])
        .map((link, index) => {
          const { name, id } = link;

          return {
            id,
            name,
            image: `${link.url}/image`,
            sku: (link.default_sku || {}).id,
            platforms: link.playable_platform,
            storeHref: buildStoreResolveUrl({
              language,
              script,
              country,
              line,
              productId: id,
              age: normalizeAge(age),
              smcid: makeSMCID({
                location,
                searchTerm,
                position: index + 1,
                productName: name,
              }),
            }),
          };
        }),
    }));
};
