/**
 * @class Responsible for dispatching analytics events to analytic services via GCT
 */
class AnalyticsReporter {
  /**
   * @param  {Object} gct - grand central telemetry instance
   * @param  {Number=} timeout - deadline for sending telemetry in milliseconds
   */
  constructor(gct, events) {
    this._gct = gct;
    this._events = events;
  }

  /**
   * Dispatches an event via GCT if the eventFactory passed in supports it.
   * eventFactory.make should return false if the event should not be dispatched.
   * @param  {Object} event - event to be cast into a GCT event type and dispatched
   * @return {Promise} Promise representing the progress of the dispatched event.
   */
  dispatch(event) {
    return new Promise((resolve) => {
      const Event = this._events[event.type];
      const gctEvent = new Event(event.payload);

      resolve(this._gct.dispatch(gctEvent));
    });
  }
}

export default AnalyticsReporter;
