import PdcSearch from './PdcSearch';
import TumblerSearch from './TumblerSearch';

/**
 * A collection of our search implementations represented as a hashmap.
 * NB: primarily used for unit testing via IoC
 */
export default {
  [PdcSearch.name]: PdcSearch,
  [TumblerSearch.name]: TumblerSearch,
};
