/** @TODO: separate dummy app assets from dist */
import '../assets/img/jetstream.png';
import './styles/sharedNav.scss';
import './styles/tertiaryNav.scss';
import './polyfills';
import createSharedNav, { getSharedNavEl } from './createSharedNav';
import autoSetPublicUrl, { setPublicUrl, getPublicUrl } from './utils/publicUrl';

const initSbSkeleton = () => {
  if (typeof (window.sbWeb || {}).isUserSignedIn === 'function') {
    if (window.sharedNav && window.sharedNav.opts) {
      window.sbWeb.disableSignOut = window.sharedNav.opts.disableSignOut;
    }

    // SB2 integrations
    window.sbWeb.onResize = () => {
      const width = document.documentElement.clientWidth;

      return (width < 1024) ? 'profile' : 'full';
    };
  }
};


autoSetPublicUrl();
const nav = createSharedNav({ wnd: window, initSbSkeleton });

// @todo Discuss naming convention of sharedNav.SharedNav
window.sharedNav = Object.assign({}, window.sharedNav, {
  setPublicUrl,
  getPublicUrl,
  SharedNav: nav,
  init: nav.init,
  updatePlatformPrivacyLevel: nav.updatePlatformPrivacyLevel,
  ...nav.initStickiness(),
});

if (getSharedNavEl(document)) {
  nav.init();
}
