import { h } from 'preact';
import bemNamespace from '../../utils/bemNamespace';

export const bem = bemNamespace('select');

const SelectOption = (props) => {
  const {
    title,
    children,
    onSelect,
    className,
    selectedIndex,
    dataQa = null,
    label = '',
    telemetryData,
  } = props;

  return (
    <button
      className={className}
      aria-label={label}
      type="button"
      onClick={onSelect}
      data-selected-index={selectedIndex}
      data-telemetry={telemetryData}
      data-qa={dataQa}
    >
      <span className={bem('label-title')}>{title}</span>
      {children}
    </button>
  );
};

export default SelectOption;
