import { h, Component } from 'preact';
import FocusTrapper from '../../utils/FocusTrapper';

/**
 * Will ensure tabbing within the trap element is circular and focus
 * does not leave
 */
export default class FocusTrap extends Component {
  componentDidMount() {
    const { onEscape } = this.props;

    this.focus = new FocusTrapper(this.node);
    this.focus.trap();
    this.focus.setOnEscape(onEscape);
  }

  componentWillUnmount() {
    this.focus.release();
  }

  // eslint-disable-next-line no-unused-vars
  render({ children, ...rest }) {
    return (
      <div ref={(node) => { this.node = node; }} {...rest}>
        {children}
      </div>
    );
  }
}
