import getQueryParam from '../../../../utils/getQueryParam';
import regionResolver, {
  SIE_JAPAN,
  SIE_ASIA,
  SIE_EUROPE,
  SIE_AMERICAS,
} from '../../../../utils/regionResolver';
import { storeToPdcMapping } from '../../../../utils/l10n';

/**
 * Get the search endpoint for all playstation.com regions
 * @param {Object} opts
 * @param {string} opts.searchTerm - search term
 * @param {string} opts.smcid - the smcid for the link
 * @returns {string} PDC search endpoint
 */
const getSearchEndpoint = ({
  searchTerm,
  smcid,
}) => {
  const searchpath = '/search/';
  let searchEndpoint = `${searchpath}?q=${encodeURIComponent(searchTerm)}&smcid=${encodeURIComponent(smcid)}`;
  const emcid = getQueryParam(window.location.search, 'emcid');

  if (emcid) {
    searchEndpoint += `&emcid=${encodeURIComponent(emcid)}`;
  }

  return searchEndpoint;
};

/**
 * Creates a search URL path for all non-SIEJ search URLS
 * @param {string} opts.searchTerm - search term
 * @param {string} opts.language - locale langague code for the PDC site
 * @param {string} opts.country - locale country code for the PDC site
 * @param {string} opts.smcid - the smcid for the link
 * @throws {Error} when opts.search, opts.language, or opts.country is falsey
 */
const formatSearchPath = ({
  country,
  language,
  searchTerm,
  smcid,
}) => {
  if (!searchTerm) { throw new Error('pdc::buildPdcSearchUrl - searchTerm is required'); }

  let locale = `${language.toLowerCase()}-${country.toLowerCase()}`;

  if (storeToPdcMapping[locale]) {
    locale = storeToPdcMapping[locale];
  }

  const searchEndpoint = getSearchEndpoint({ searchTerm, smcid });

  return `${locale}${searchEndpoint}`;
};

/**
 * Provides a SIEA / SIEE PDC search destination URL for a given country, language and search term
 * @param {Object} opts
 * @param {string} opts.searchTerm - search term
 * @param {string} opts.language - locale langague code for the PDC site
 * @param {string} opts.country - locale country code for the PDC site
 * @param {string} opts.smcid - the smcid for the link
 * @returns {string} PDC search page link
 */
const formatSieeOrSieaUrl = opts => `https://www.playstation.com/${formatSearchPath(opts)}`;

/**
 * Provides a SIEA / SIEE PDC search destination URL for a given country, language and search term
 * @param {Object} opts
 * @param {string} opts.searchTerm - search term
 * @param {string} opts.language - locale langague code for the PDC site
 * @param {string} opts.country - locale country code for the PDC site
 * @param {string} opts.smcid - the smcid for the link
 * @returns {string} PDC search page link
 */
const formatSieAsiaUrl = (opts) => {
  const pdcMappings = {
    'zh-hans': 'chs',
    'zh-hant': 'cht',
  };

  const searchOpts = {
    ...opts,
  };

  if (opts.script) {
    const languageAndScript = `${opts.language}-${opts.script}`;

    if (pdcMappings[languageAndScript]) {
      searchOpts.language = pdcMappings[languageAndScript];
    }
  }

  return (opts.country === 'cn')
    ? `https://asia.playstation.com.cn/${formatSearchPath(searchOpts)}`
    : `https://www.playstation.com/${formatSearchPath(searchOpts)}`;
};

/**
 * Provides a PDC search destination URL for a given country, language and search term
 * @param {Object} opts
 * @param {string} opts.searchTerm - search term
 * @param {string} opts.language - locale langague code for the PDC site
 * @param {string} opts.country - locale country code for the PDC site
 * @param {string} opts.smcid - the smcid for the link
 * @throws {Error} when opts.search, opts.language, or opts.country is falsey
 * @returns {string} PDC search page link
 */
export default (opts) => {
  const { language = '', country = '', script } = opts;

  if (!language) { throw new Error('buildPdcSearchUrl: language is required'); }
  if (!country) { throw new Error('buildPdcSearchUrl: country is required'); }

  const locale = script ? `${language}-${script}-${country}` : `${language}-${country}`;
  const region = regionResolver(storeToPdcMapping[locale] || locale);
  const searchOpts = Object.assign({}, opts, { region });

  switch (region) {
    case SIE_EUROPE:
    case SIE_JAPAN:
    case SIE_AMERICAS: return formatSieeOrSieaUrl(searchOpts);
    case SIE_ASIA: return formatSieAsiaUrl(searchOpts);
    default: {
      throw new Error(`buildPdcSearchUrl: could not map the locale "${locale}" to a region`);
    }
  }
};
