import Search from './Search';

/** @type {Search} */
let search;

/**
 * @param {Object} config
 * @param {Element} config.returnEl - Element which should be focused after
 *                                    closing the search module
 * @param {string} config.language - User's language (should be derived from PSN
 *                                   account information when possible)
 * @param {string} config.country - User's country (should be derived from PSN account
 *                                  information when possible)
 * @param {string} config.line - PSN integration line (eg. e1-np, np, etc)
 * @param {string} config.searchSource - The search context - currently either PDC or Store
 * @param {boolean} config.showSearchContextSelector - Boolean indicating if the search
 *                                                     selector should be visible. In some
 *                                                     PDC locales, marketing would
 *                                                     only like store search available.
 */
export default (config) => {
  if (search) {
    search.mount();
    return;
  }

  search = new Search(config);
  search.setup();
  search.mount();
};
