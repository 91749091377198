import createElementFromHTML from '../utils/createElementFromHTML';

/**
 * Responsible for rendering the sony bar into the DOM at the given parent element.
 * @param {string} linkUrl - where the user is taken if sony logo is clicked
 * @param {window} param1 - window object
 * @return {HTMLElement|null} the created sony bar
 */
export default function sonyBar(linkUrl, { document }) {
  const sonyLinkHTML = `
    <a href="${linkUrl}" class="dtm-no-track">
      <span class="sony-logo sony-bar__logo"></span>
      <span class="sr-only">Sony</span>
    </a>`;

  // happens if the sony bar was rendered in a SSR context
  const existingSonyBar = document.getElementById('sony-bar');
  if (existingSonyBar) {
    existingSonyBar.innerHTML = sonyLinkHTML;
    return existingSonyBar;
  }

  const sonyBarEl = createElementFromHTML(`
    <div id="sony-bar" class="sony-bar">${sonyLinkHTML}</div>
  `, document);

  const sharedNavRootEl = document.getElementById('shared-nav-root');
  if (!sharedNavRootEl) {
    throw new Error('SonyBar: could not find #shared-nav-root element');
  }

  sharedNavRootEl
    .parentNode
    .insertBefore(sonyBarEl, sharedNavRootEl);

  return sonyBarEl;
}
