import { h, Component } from 'preact';
import { clickAway } from '../../utils/ClickAwayContext';

/**
 * Component Wrapper To Handle Click-Away Behavior
 * This code was taken from the Raven POC
 * @see https://github.sie.sony.com/SIE/raven/blob/master/src/components/ClickAway/index.jsx
 */
export default class ClickAway extends Component {
  componentDidMount() {
    const { onClickAway } = this.props;

    this.#remove = clickAway().add({ node: this.#node, onClickAway });
  }

  componentWillUnmount() {
    this.#remove();
  }

  #node = null;

  #remove = null;

  // eslint-disable-next-line no-unused-vars
  render({ onClickAway, children, ...rest }) {
    return (
      <div ref={(node) => { this.#node = node; }} {...rest}>
        {children}
      </div>
    );
  }
}
