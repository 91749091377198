/** 99 represents an unknown age */
const TUMBLER_AGE_UKNOWN = 99;

/** Any age less than 19 needs to be sent verbatim to tumbler to filter results */
const TUMBLER_AGE_MAX = 19;

/**
 * There are only a few age ranges which matter for the store search
 * @param {number} age
 * @returns {number} normalized age
 */
const normalizeAge = age => (
  age === TUMBLER_AGE_UKNOWN
    ? TUMBLER_AGE_UKNOWN
    : Math.min(TUMBLER_AGE_MAX, age)
);

export default normalizeAge;
