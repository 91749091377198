import { h, Component } from 'preact';
import bemNamespace from '../../utils/bemNamespace';
import ClickAway from '../ClickAway';
import FocusTrap from '../FocusTrap';
import SelectOption from './SelectOption';
import SelectOptions from './SelectOptions';

const bem = bemNamespace('select');
const iconBem = bemNamespace('shared-nav-icon');

export default class Select extends Component {
  constructor(props) {
    super(props);

    const { placeholder, selectedIndex: index } = this.props;
    let selectedIndex = (placeholder && -1) || index || 0;

    const options = props.options || [];
    options.forEach(({ isDefault }, optionIndex) => {
      if (isDefault) {
        selectedIndex = optionIndex;
      }
    });

    this.state = { selectedIndex, isOpen: false };
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    this.notifyParentOfInitialValue();

    if (autoFocus) {
      this.primaryLabelEl.base.focus();
    }
  }

  close = (event = {}) => {
    const { isOpen } = this.state;

    if (!isOpen) return;

    if (isOpen && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }

    this.setState({ isOpen: false });

    setTimeout(() => this.primaryLabelEl.base.focus());
  };

  toggleOpen = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  selectOptionAndClose = ({ index, value }) => {
    this.selectOption({ index, value });
    this.close();
  };

  selectOption({ index, value }) {
    const { onChange } = this.props;
    const { selectedIndex } = this.state;

    if (index !== selectedIndex) {
      this.setState({ selectedIndex: index });
      if (typeof onChange === 'function') {
        onChange({ index, value });
      } else {
        console.log('onChange handler not set');
      }
    }
  }

  /** @private */
  notifyParentOfInitialValue() {
    const { selectedIndex } = this.state;
    const { options = { 0: { value: null } } } = this.props;

    if (selectedIndex !== -1) {
      this.selectOption({ index: selectedIndex, value: options[selectedIndex].value });
    }
  }

  determineTitle() {
    const {
      openTitle = '',
      placeholder,
      options = [],
    } = this.props;

    const { isOpen = false, selectedIndex = 0 } = this.state;

    if (isOpen && openTitle) {
      return openTitle;
    }

    // The placeholder text is selected
    if (selectedIndex === -1) {
      return placeholder;
    }

    return (options[selectedIndex] || {}).title;
  }

  render() {
    const {
      className = '',
      options = [],
      label = '',
      dataQa = null,
      autoFocus,
      openTitle,
    } = this.props;

    const { selectedIndex, isOpen } = this.state;
    const isPlaceholderSelected = selectedIndex === -1;
    const primaryTitle = this.determineTitle();
    const isOpenTitle = primaryTitle === openTitle;
    const optionTypeClass = bem('label', (isPlaceholderSelected || isOpenTitle) ? 'placeholder' : 'primary');
    const openStateClass = isOpen ? bem('label', 'open') : '';

    return (
      <ClickAway
        className={`${bem()} ${className}`}
        onClickAway={this.close}
      >
        <SelectOption
          selectedIndex={selectedIndex}
          title={primaryTitle}
          className={`dtm-no-track ${optionTypeClass} ${openStateClass}`}
          onSelect={this.toggleOpen}
          ref={(el) => { this.primaryLabelEl = el; }}
          autoFocus={autoFocus}
          label={label}
          dataQa={dataQa}
        >
          <span className={
            `${bem('chevron')} ${iconBem('', `chevron-${isOpen ? 'top' : 'down'}`)}`
          }
          />
        </SelectOption>
        {
          isOpen && (
            <FocusTrap onEscape={this.close}>
              <SelectOptions
                onSelect={this.selectOptionAndClose}
                selectedIndex={selectedIndex}
                options={options}
              />
            </FocusTrap>
          )
        }
      </ClickAway>
    );
  }
}
