import debounce from 'debounce-promise';
import { slideY, animateTogether, nullAnimation } from './animation';

/**
 * Smooths over browser differences for scrollY position (e.g. IE11 uses pageYOffset)
 * @return {Number} the number of pixels scrolled
 */
export const getScrollY = () => window.scrollY || window.pageYOffset;

/**
 * Gives an element stickiness in conjunction with Sticky class
 * @param {Object} config
 * @param {HTMLElement} config.element - element to make sticky
 * @param {HTMLElement} [config.pullsElement] - element to pull / push on scroll
 * @param {Number} [config.threshold=0] - when the elements top passes this fixed
 *    pixel value on the screen it should become sticky
 * @see Sticky
 */
export default class StickyElement {
  /**
   * @type {HTMLElement} - the element to make sticky
   */
  el = null;

  /**
   * @type {HTMLElement | null} - the element to pull (and push) on scroll
   */
  pulls = null;

  /**
   * @type {HTMLElement | null} - the optional element attached at the bottom of a sticky element
   */
  attached = null;

  #mainHeight = 0;

  /**
   * @type {Function} - returning the value where the element should become sticky
   * @private
   */
  #threshold = () => 0;

  /**
   * @type {Function} - returning the amount of additional space (padding) to account for
   *  when saving space in the document
   */
  #offset = () => 0;

  /**
   * @type {Number} - the sticky elements original offsetTop value
   * @private
   */
  #offsetTop = 0;

  /**
   * @type {boolean} - whether or not the element is in the main sticky element
   * controlling the positioning of the pulls and attached elements
   * @private
   */
  #isMain = true;

  /**
   * @type {boolean} - whether or not the element is in the stuck state
   * @private
   */
  #isStuck = false;

  /**
   * @type {boolean} - whether or not the element is in the pulled state
   *    can only occur when a pulls element is present
   * @private
   */
  #isPulled = false;

  /**
   * @type {boolean} - whether or not to force the element in to a locked state
   *  elements will not animate when locked
   * @private
   */
  #isLocked = false;

  /**
   * @type {Animation} - the animation to play on push / pull
   * @private
   */
  #animation = nullAnimation;

  constructor({
    element,
    pullsElement,
    offset = () => 0,
    threshold = () => 0,
    isMain = true,
  } = {}) {
    if (!element) {
      throw new Error('element is required');
    }

    this.el = element;
    this.#offset = offset;
    this.#threshold = threshold;
    this.pulls = pullsElement && new StickyElement({
      element: pullsElement,
      threshold: () => this.#threshold() - pullsElement.offsetHeight,
      isMain: false,
    });
    // Cache the original offsetTop value so we can use it as a reference
    this.#offsetTop = this.offsetTop();
    this.#isMain = isMain;

    if (this.pulls) {
      this.pulls.el.addEventListener('focusin', this.pull.bind(this));
    }

    if (this.#isMain) {
      window.sticky = this;

      const sharedNavContainerEl = document.getElementById('shared-nav-container');

      if (sharedNavContainerEl !== this.el) {
        this.monitorMainResize();
      }
    }
  }

  monitorMainResize() {
    this.#mainHeight = this.el.offsetHeight;

    const adjustAttachedTopPosition = () => {
      if (this.el.offsetHeight !== this.#mainHeight) {
        this.#mainHeight = this.el.offsetHeight;
        this.unStick();
      }
    };
    const debouncedResize = debounce(adjustAttachedTopPosition, 100);
    const resizeObserver = new ResizeObserver(debouncedResize);

    resizeObserver.observe(this.el);
  }

  /**
   * Push and Pull behavior (optional)
   * When a sticky element has a pullsElement then there are additional states beyond simply
   * "stuck" or "unstuck". In this case the sticky element and pullsElement are linked.
   */

  /**
   * Push will begin to push / slide the elements in unison up by the pullsElement height in the
   * y direction.
   */
  push() {
    if (!this.shouldPush()) return;

    this.#isPulled = false;
    this.animate('push');
  }

  shouldPush() {
    return this.canAnimate() && this.#isPulled && !this.isPullStateLocked();
  }

  /**
   * Pull will begin to pull / slide the elements in unison down by the pullsElement height in the
   * y direction.
   */
  pull() {
    if (!this.shouldPull()) return;

    this.#isPulled = true;
    this.animate('pull');
  }

  shouldPull() {
    return this.canAnimate() && !this.#isPulled;
  }

  canAnimate() {
    return this.#isStuck
      && this.pulls
      && !this.#animation.isAnimating();
  }

  animate(type) {
    const isPush = type === 'push';
    const distance = this.pulls.height();
    const to = isPush ? 0 : distance;
    const from = isPush ? distance : 0;

    // TODO: find a better way to do this
    // 1 frame delay added to ensure no gap is shown between elements
    // when animating. Without the delay the background bleeds through at the
    // edge of the pulls element and the stuck element
    this.#animation = animateTogether(
      slideY(this.el, { from, to, delay: isPush ? 0 : 10 }),
      slideY(this.pulls.el, { from, to, delay: isPush ? 10 : 0 }),
      this.attached && slideY(this.attached.el, { from, to, delay: isPush ? 0 : 10 }),
    ).start();
  }

  // The top position of the element relative to the page, el.offsetTop can lead to
  // misleading values because it's calculated relative to it's parent.
  // Also getBoundingClientRect() takes amount of scroll into consideration,
  // add the scrollY to get the accurate value.
  offsetTop() {
    const elTop = this.el.getBoundingClientRect().top;
    return elTop < 0 ? elTop + window.scrollY : elTop;
  }

  height() {
    return this.el.offsetHeight;
  }

  // Calculates the total space to take up in the document when stuck (fixed position).
  // This is to prevent the document content from jumping when moving from stuck / to
  // unstuck. We do not consider the height of the pulls element here due to the animation
  // and the possibility for pushing any elements (sonyBar) below the stuck header elements
  totalSpace() {
    const attachedElHeight = this.attached ? this.attached.height() : 0;
    const pullsElHeight = this.pulls ? this.pulls.height() : 0;

    return attachedElHeight + this.height() + pullsElHeight + this.#offset();
  }

  hasFocus() {
    return this.el.contains(document.activeElement);
  }

  shouldStick() {
    const pullsHeight = this.pulls ? this.pulls.height() : 0;
    const shouldAdjustThreshold = this.#isPulled || this.isPullStateLocked();
    const threshold = shouldAdjustThreshold ? this.#threshold() + pullsHeight : this.#threshold();

    return (this.#offsetTop - getScrollY()) <= threshold;
  }

  isPullStateLocked() {
    if (!this.pulls) return false;

    return this.pulls.hasFocus() || this.#isLocked;
  }

  attach(element) {
    this.attached = new StickyElement({
      element,
      threshold: () => this.#threshold() + this.height(),
      isMain: false,
    });
  }

  #saveSpace = () => {
    if (!this.#isMain) return;

    document.body.style.paddingTop = `${this.totalSpace()}px`;
  }

  #resetSpace = () => {
    if (!this.#isMain) return;

    document.body.style.paddingTop = null;
  }

  unStick() {
    if (!this.#isStuck) return;

    this.#resetSpace();
    this.#animation.cancel();
    this.#isStuck = false;
    this.#isPulled = false;
    this.el.style.position = 'relative';
    this.el.style.left = 'auto';
    this.el.style.top = 'auto';
    this.el.style.transform = 'translateZ(0)';

    if (this.pulls) {
      this.pulls.unStick();
    }

    if (this.attached) {
      this.attached.unStick();
    }

    // Re-compute the offsetTop to account for changes due to viewport changes / responsive
    // design elements e.g. in shared-nav sony bar appears at the top in desktop views and
    // on the bottom in mobile requiring adjustment when dynamically resizing the browser window.
    this.#offsetTop = this.offsetTop();
  }

  stick() {
    if (this.#isStuck) return;

    this.#saveSpace();
    this.#isStuck = true;
    this.el.style.position = 'fixed';
    this.el.style.left = '0';
    this.el.style.top = `${this.#threshold()}px`;

    if (this.isPullStateLocked()) {
      const pullsHeight = this.pulls.height();

      this.#isPulled = true;
      this.el.style.transform = `translateY(${pullsHeight}px)`;
      this.pulls.el.style.transform = `translateY(${pullsHeight}px)`;

      if (this.attached) {
        this.attached.el.style.transform = `translateY(${pullsHeight}px)`;
      }
    }

    if (this.pulls) {
      this.pulls.stick();
    }

    if (this.attached) {
      this.attached.stick();
    }
  }

  forceUnstick() {
    this.#resetSpace();
    this.#animation.cancel();
    this.#isStuck = false;
    this.#isPulled = false;
    this.el.style.position = 'relative';
    this.el.style.left = 'auto';
    this.el.style.top = 'auto';
    this.el.style.transform = 'translateZ(0)';

    if (this.pulls) {
      this.pulls.forceUnstick();
    }

    if (this.attached) {
      this.attached.forceUnstick();
    }
  }
}
