import { h, Component } from 'preact';
import bemNamespace from '../../utils/bemNamespace';
import SelectOption from './SelectOption';

const selectBem = bemNamespace('select');

export default class Options extends Component {
  componentDidMount() {
    const { selectedIndex } = this.props;

    this[`option-${selectedIndex}`].base.focus();
  }

  render() {
    const { options, selectedIndex, onSelect } = this.props;
    return (
      <ul className={selectBem('options')}>
        {
          options.map((option, idx) => (
            <li key={option.value}>
              <SelectOption
                label={option.label}
                title={option.title}
                className={selectBem('label', selectedIndex === idx ? 'selected' : '')}
                onSelect={() => onSelect({ index: idx, value: option.value })}
                ref={(o) => { this[`option-${idx}`] = o; }}
                telemetryData={option.telemetryData}
                dataQa={`select-option-${option.value}`}
              />
            </li>
          ))
        }
      </ul>
    );
  }
}
