import stringSets from './stringSets';
import placeholders from './placeholders';
import interpolator from './interpolator';

const loadStringsFor = (locale) => {
  if (!locale) {
    throw new Error('l10n: no locale was provided');
  }

  const normalizedLocale = locale.toLowerCase();

  if (!stringSets[normalizedLocale]) {
    throw new Error(`l10n: localization set does not exist for "${locale}"`);
  }

  if (Object.keys(placeholders).length > 0) {
    console.warn(
      'l10n: placeholder strings detected. Please resolve the '
      + `following strings before releasing this code: ${Object.keys(placeholders).join(', ')}`,
    );
  }

  return {
    ...stringSets[normalizedLocale],
    ...placeholders,
  };
};


/**
 * Returns the l10n stringset for a given locale
 * @param {string} locale - code (eg. en-us)
 * @return {Function}
 */
export default (locale = '') => {
  const strings = loadStringsFor(locale);
  return (locId, ...rest) => {
    if (!locId) { return ''; }
    return !strings[locId]
      ? `l10n: missing translation for string "${locId}"`
      : interpolator(strings[locId], ...rest);
  };
};
