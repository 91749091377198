import createElementFromHTML from './utils/createElementFromHTML';
import l10n from './utils/l10n';

export default (locale, document, buildSkipLink, skipToAnchor) => {
  if (!buildSkipLink) return;

  const getLocalizedString = l10n(locale);
  // More semantically correct as there is no context change (i.e. user stays in same page)
  const skipLinkBtn = `<button id="skip-link">${getLocalizedString('msg_skip_to_main_content')}</button>`;
  const skipLinkBtnEl = createElementFromHTML(skipLinkBtn, document);

  document.body.insertBefore(skipLinkBtnEl, document.body.firstChild);

  const skipToEl = document.querySelector(`#${skipToAnchor}`);

  if (skipToEl && skipLinkBtnEl) {
    skipToEl.addEventListener('blur', () => skipToEl.removeAttribute('tabindex'));

    skipLinkBtnEl.addEventListener('click', () => {
      skipToEl.setAttribute('tabindex', -1);
      skipToEl.focus();

      return true;
    });
  }
};
