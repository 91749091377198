import trackingClicks from './clickTracker';
import trackingPerf from './perfTracker';
import TelemetryBuffer from './TelemetryBuffer';
import TelemetryDataFactory from './TelemetryDataFactory';
import TelemetryLocator from './TelemetryLocator';
import TelemetryFacade from './TelemetryFacade';

/**
 * Entry point for bootstrapping telemetry
 *
 * @param {Object} config
 * @param {string} scope - the identifier for whether events get handled by
 *  the telemetry tracking instance
 * @returns {TelemetryFacade}
 */
const initTelemetryTracking = ({ scope, resolveLocation }) => {
  const locator = new TelemetryLocator({ resolveLocation });
  const buffer = new TelemetryBuffer({ locator });
  const dataFactory = new TelemetryDataFactory({ scope });
  const telemetry = new TelemetryFacade({
    buffer, dataFactory, locator,
  });

  telemetry.start(trackingClicks({ scope }));
  telemetry.start(trackingPerf());

  window.sharedNav.telemetry = telemetry;

  return telemetry;
};

export default initTelemetryTracking;
