const makeMatchedMeasurement = (markName) => {
  const [measureName] = (markName.match(/^(.+):end$/) || []).slice(1);

  try {
    performance.measure(measureName, `${measureName}:start`, markName);
  } catch (e) {
    console.warn(`matching start mark for ${markName} was not found, measurement cannot be made`);
  }

  return measureName;
};

const makeRelativeMeasurement = (markName) => {
  const [measureName, fromMark] = markName.split(/:from:/);
  const finalFromMark = fromMark.replace(/:complete$/, '');

  try {
    performance.measure(measureName, finalFromMark, markName);
  } catch (e) {
    console.warn(`Relative start mark ${finalFromMark} was not found, measurement cannot be made`, e);
  }

  return measureName;
};

const captureLoadTimeEvent = ({ telemetry, entryName }) => {
  const { performance } = window;
  const [entry] = performance.getEntriesByName(entryName);

  if (!entry) {
    console.warn('could not get perf end time, skipping reporting load time event');
    return;
  }

  const {
    startTime,
    duration,
    name,
    entryType,
  } = entry || {};
  const absolutePerfTiming = { ...entry, startTime: 0, duration: startTime };
  const loadTimeMeasure = entryType === 'mark' ? absolutePerfTiming : { startTime, duration, name };
  const timings = performance.getEntries().filter(e => e.name !== entryName);
  const loadTimeEvent = telemetry.makeEvent({
    type: entryName,
    vars: { timings: [loadTimeMeasure].concat(timings) },
  });

  telemetry.capture(loadTimeEvent);
};

let isActive;

/**
 * Responsible for providing a performance communication protocol via performance.mark
 *
 * @returns {Function} must be called with telemetry facade
 * @example
 *
 * telemetry.start(trackingPerf());
 *
 * # mark protocol
 *
 * ## Directives
 *
 * ### `:start`
 * This directive begins a measurement
 *
 * ### `:end`
 * This directive ends thes the measurement from the `<mark-name>:start` mark
 * @example
 * window.performance.mark('some-thing:start');
 * window.performance.mark('some-thing:end');
 *
 * // measurement with name `something` is created
 * const [measure] = window.performance.getEntriesByName('some-thing');
 *
 * ### `:from:`
 * This directive makes a measurement from an arbitrary mark name
 * @example
 * window.performance.mark('new-origin');
 * window.performance.mark('tti:from:new-origin');
 *
 * // measurement with name `tti` is created
 * const [measure] = window.performance.getEntriesByName('tti');
 *
 * ### `:complete`
 * This directive tells mark to send out a LoadTimeEvent to the telemetry reporter
 * The spec with the matching namespace and spec type will be used to format the
 * event for reporting. The spec will be send all entries and the spec will
 * indicate which entries are relevant and match a specific Clairvoyance metric Id
 * @example
 * // finds spec with namespace `search` and the type `tti`
 * window.performance.mark('search:tti:complete');
 *
 * @example With :from:
 * // finds spec with namespace `search` and the type `tti`
 * window.performance.mark('search:tti:from:new-origin:complete');
 */
const trackPerf = () => (telemetry) => {
  if (isActive) return false;

  isActive = true;

  const { performance } = window;
  const ogPerformanceMark = performance.mark;
  const stop = () => {
    isActive = false;
    performance.mark = ogPerformanceMark;
  };

  performance.mark = (markName) => {
    const shouldCapture = /:complete$/.test(markName);
    const useMarkName = shouldCapture ? markName.replace(/:complete$/, '') : markName;
    let relativeEntryName;

    ogPerformanceMark.call(performance, markName);

    if (/:from:/.test(useMarkName)) {
      relativeEntryName = makeRelativeMeasurement(markName);
    }

    if (/:end$/.test(useMarkName)) {
      makeMatchedMeasurement(markName);
    }

    if (shouldCapture) {
      performance.mark(useMarkName);
      captureLoadTimeEvent({ telemetry, entryName: relativeEntryName || useMarkName });
    }
  };

  return stop;
};

export default trackPerf;
