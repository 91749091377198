import { h, Component } from 'preact';
import SearchTextBox from '../../components/SearchTextBox';
import Select from '../../components/Select';
import ClickAway from '../../components/ClickAway';
import FocusTrap from '../../components/FocusTrap';
import TumblerSearchResults from '../../components/TumblerSearchResults';
import bemNamespace from '../../utils/bemNamespace';
import NAVIGATION_TIMEOUT from '../../../utils/telemetry/telemetryConfig';
import getQueryParam from '../../../utils/getQueryParam';
import sanitizeSearchTerm from '../../services/SearchFactory/TumblerSearch/sanitizeSearchTerm';

const FADE_ANIMATION_DURATION_MS = 500;

const nullEvent = { preventDefault() {} };

const searchOptions = (telemetry, l10n, defaultOption) => {
  const pdc = 'pdc';
  const store = 'store';

  return telemetry.decorate([
    { title: l10n('msg_playstation_dot_com'), value: pdc, isDefault: defaultOption === pdc },
    { title: l10n('msg_playstation_store'), value: store, isDefault: defaultOption === store },
  ]).withEvent({ type: 'search:click:search-filter' });
};

const isJetstreamSearchV2 = process.env.SEARCHV2_ENABLED === 'true';

const isSearchTermBlank = term => term.trim() === '';

/** The default search context */
export const DEFAULT_SEARCH_SOURCE = 'pdc';

export const bem = bemNamespace('jetstream-search');

/**
 * Container Component For The Main Search Screen
 * Encompasses everything from the opaque background to the results list.
 */
export default class SearchScreen extends Component {
  state = {
    searchTerm: '',
    // Indicates whether the user has selected to search the store or PDC
    selectedSearchSource: DEFAULT_SEARCH_SOURCE,
    // The results of the last executed search.
    // NB: this will be cleared anytime the search source has been updated.
    search: {
      // The actual results
      results: [],
      // The URL where all search results can be seen
      seeAllUrl: '',
    },
    // Boolean indicating if the search results are loading
    isSearchLoading: false,
    // Indicates if we're between the period the user has indicated they would
    // like to close the search and when the controls have disappeared.
    // nb: this is used to apply interstitial classes to apply a smooth
    //     CSS transition for hiding search.
    isClosing: false,
  };

  /**
   *
   * @param {Object} props
   * @param {SearchFactory} props.searchFactory
   * @param {string} props.searchSource
   * @param {Function} props.l10n
   * @param {boolean} props.showSearchContextSelector
   */
  constructor(props) {
    super(props);
    this.setSearchSource(props.searchSource);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.handleSearchTypeChange = this.handleSearchTypeChange.bind(this);
    this.handleSearchClose = this.handleSearchClose.bind(this);
    this.handleSearchRedirect = this.handleSearchRedirect.bind(this);
  }

  componentDidMount() {
    const { telemetry } = this.props;
    const smcid = getQueryParam(window.location.search, 'smcid');
    const emcid = getQueryParam(window.location.search, 'emcid');

    telemetry.capture(telemetry.makeEvent({ type: 'search:page-view', referrer: smcid, marketingCampaign: emcid }));
    window.performance.mark('search:tti:from:search:open:complete');
  }

  setSearchSource(domain = DEFAULT_SEARCH_SOURCE) {
    const { searchFactory } = this.props;

    this.searchDomain = searchFactory.make(domain);
    this.setState({ selectedSearchSource: domain });
  }

  onSearchLinkClick = (event = nullEvent, { productId, searchResult = 'see all', searchPosition = 'see all' } = {}) => {
    const { searchTerm } = this.state;
    const { onLinkClick } = this.searchDomain;

    if (typeof onLinkClick === 'function') {
      event.preventDefault();
      onLinkClick({
        searchTerm,
        productId,
        searchResult,
        searchPosition,
      });
    }

    this.handleSearchClose();
  };

  getSearchLabel = (l10n, showSearchContextSelector, selectedSearchSource) => {
    if (showSearchContextSelector) {
      return selectedSearchSource === 'store'
        ? l10n('msg_ps_store_change_search')
        : l10n('msg_playstation_dot_com_change_search');
    }
    // Don't use label that has "navigating to select" instruction if we don't show select
    return selectedSearchSource === 'store'
      ? l10n('msg_search_playstation_store')
      : l10n('msg_search_playstation_dot_com');
  }

  /**
   * Invoked when the user toggles between searching the store and PDC
   * @param {Event} - param0
   */
  handleSearchTypeChange = ({ value }) => {
    this.clearSearch();
    this.setSearchSource(value);

    if (value === 'store') {
      const { searchTerm } = this.state;
      this.handleSearchInput({ target: { value: searchTerm } });
    }
  };

  /**
   * Invoked when the user supplies text the input box
   * @param {Event} event - Event
   */
  handleSearchInput({ target: { value: searchTerm } }) {
    if (isSearchTermBlank(searchTerm)) {
      this.clearSearchTerm();
      this.clearSearch();
      return;
    }

    const { telemetry } = this.props;
    const telemetryEventMeta = this.#makeSearchResultTelemetryMeta(searchTerm);

    this.setState({
      searchTerm,
      isSearchLoading: true,
    });

    if (!isJetstreamSearchV2) {
      this.searchDomain.search(searchTerm)
        .then(({ results = [], seeAllUrl }) => {
          this.setState({
            search: {
              results: telemetry.decorate(results).withEvent(telemetryEventMeta),
              seeAllUrlTelemetryData: telemetry.makeEvent(telemetryEventMeta),
              seeAllUrl,
            },
            isSearchLoading: false,
          });
        })
        .catch(() => this.setState({ isSearchLoading: false }));
    }
  }

  /**
   * Accepts user input data when the search control is set to search PDC
   * Really, this function just waits for an "enter" keystroke to take the user to
   * PDC's native search page.
   */
  handleSearchRedirect() {
    const { searchTerm, selectedSearchSource } = this.state;
    const isStoreSearch = selectedSearchSource === 'store';
    const sanitizedSearchTerm = (isStoreSearch && !isJetstreamSearchV2)
      ? sanitizeSearchTerm(searchTerm) : encodeURIComponent(searchTerm);

    if (isSearchTermBlank(sanitizedSearchTerm)) {
      this.clearSearchTerm();
      this.clearSearch();
      return;
    }

    const { telemetry } = this.props;
    const telemetryData = telemetry.makeEvent(this.#makeSearchResultTelemetryMeta(searchTerm));
    const { isLinkHandled } = telemetryData;
    const goToLink = isLinkHandled ? () => {} : () => window.open(this.searchDomain.getUrl(searchTerm), '_self');

    telemetry.capture({
      ...telemetryData,
      afterSent: goToLink,
    });

    // Navigate to link in case telemetry sending fails.
    setTimeout(goToLink, NAVIGATION_TIMEOUT);

    this.onSearchLinkClick();
  }

  #makeSearchResultTelemetryMeta = searchTerm => ({
    type: 'search:click:search-result',
    vars: {
      searchTerm,
      searchDomain: this.searchDomain.name,
      isLinkHandled: !!this.searchDomain.onLinkClick,
    },
  });

  clearSearchTerm() {
    this.setState({
      searchTerm: '',
    });
  }

  /**
   * Clears the search results.
   * Used for resetting a search when the source has been changed,
   * or if the search term falls below the minimum search limit.
   */
  clearSearch() {
    this.setState({
      search: { results: [], seeAllUrl: '' },
      isSearchLoading: false,
    });
  }

  /**
   * Invoked when the "X" icon is clicked,
   * indicating it's time for the search form to become invisible.
   */
  handleSearchClose() {
    const { onSearchClose = () => {} } = this.props;
    this.setState(() => ({ isClosing: true }));
    setTimeout(onSearchClose, FADE_ANIMATION_DURATION_MS);
  }

  render() {
    const { l10n, telemetry, showSearchContextSelector = true } = this.props;
    const {
      searchTerm,
      isSearchLoading,
      search: { results, seeAllUrl, seeAllUrlTelemetryData },
      selectedSearchSource,
      isClosing,
    } = this.state;

    const shouldShowSearchResults = results.length > 0 && searchTerm;
    const searchPlaceholder = selectedSearchSource === 'store'
      ? l10n('msg_search_playstation_store')
      : l10n('msg_search_playstation_dot_com');
    const searchLabel = this.getSearchLabel(l10n, showSearchContextSelector, selectedSearchSource);

    return (
      <FocusTrap
        className={bem('', isClosing && 'is-closing')}
        onEscape={this.handleSearchClose}
      >
        <ClickAway
          role="dialog"
          aria-label={l10n('msg_search')}
          className={bem('wrapper', isClosing ? 'is-closing' : '')}
          onClickAway={this.handleSearchClose}
        >
          <div className={bem('input')}>

            <button className={`dtm-no-track ${bem('back-icon-wrapper')}`} data-qa="search-back-button" type="button" onClick={this.handleSearchClose} aria-label={l10n('msg_cancel_search')}>
              <span className={`shared-nav-icon shared-nav-icon--chevron-left ${bem('chevron-left')}`} />
            </button>

            {
              showSearchContextSelector && (
                <Select
                  className={bem('select')}
                  options={searchOptions(telemetry, l10n, selectedSearchSource)}
                  onChange={this.handleSearchTypeChange}
                  label={l10n('msg_choose_site_to_search')}
                  openTitle={l10n('msg_sites')}
                  autoFocus={false}
                  dataQa="search-select"
                />
              )
            }
            <SearchTextBox
              onInput={this.handleSearchInput}
              onEnter={this.handleSearchRedirect}
              value={searchTerm}
              placeholder={searchPlaceholder}
              label={searchLabel}
              autoFocus
              className={bem('text-box')}
            >
              {
                shouldShowSearchResults && (
                  <TumblerSearchResults
                    isLoading={isSearchLoading}
                    products={results}
                    l10n={l10n}
                    seeAllUrl={seeAllUrl}
                    seeAllUrlTelemetryData={seeAllUrlTelemetryData}
                    onLinkClick={this.onSearchLinkClick}
                  />
                )
              }
            </SearchTextBox>

            <button type="button" className={`dtm-no-track ${bem('search-button')}`} onClick={this.handleSearchRedirect}>
              <span className={bem('offscreen-label')}>{l10n('msg_search')}</span>
              <svg
                aria-hidden="true"
                focusable="false"
                width="50px"
                height="50px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 50 50"
              >
                <g>
                  <path d="M8,20.913 C8,14.344 13.344,9 19.913,9 C26.482,9 31.827,14.344 31.827,20.913 C31.827,27.482 26.482,32.827 19.913,32.827 C13.344,32.827 8,27.482 8,20.913 M45.112,43.585 L32.346,30.82 C34.518,28.099 35.827,24.658 35.827,20.913 C35.827,12.139 28.688,5 19.913,5 C11.139,5 4,12.139 4,20.913 C4,29.688 11.139,36.827 19.913,36.827 C23.503,36.827 26.808,35.618 29.474,33.604 L42.284,46.413 C42.674,46.804 43.186,46.999 43.698,46.999 C44.209,46.999 44.721,46.804 45.112,46.413 C45.502,46.023 45.698,45.511 45.698,44.999 C45.698,44.488 45.502,43.976 45.112,43.585" />
                </g>
              </svg>
            </button>

            <button className={bem('offscreen-search-btn')} type="button" onClick={this.handleSearchClose}>
              {l10n('msg_cancel_search')}
            </button>
          </div>
        </ClickAway>
      </FocusTrap>
    );
  }
}
