/**
 * Responsible for ensure screen readers cannot reach content outside of focused element container
 * @see {@link https://github.sie.sony.com/SIE/psweb-getting-started/blob/ac26d8745d7944f5a7c6b573be9806ef13e31505/tutorials/accessibility/accessible-modal/README.md} for explanation of use
 */
export default class ScreenReaderFocuser {
  _getElements() {
    const children = [].slice.call(document.body.children);

    return children.filter(el => el.tagName !== 'SCRIPT' && el !== this.focusedElement);
  }

  focus(element) {
    if (this.focusedElement && this.focusedElement !== element) {
      this.unfocus(this.focusedElement);
    }

    this.focusedElement = element;
    this._getElements().forEach((el) => {
      // If the child element already has aria-hidden set, save the aria-hidden state
      if (el.hasAttribute('aria-hidden')) {
        el.setAttribute('aria-hidden-state', el.getAttribute('aria-hidden'));
      }
      el.setAttribute('aria-hidden', true);
    });
  }

  unfocus(element) {
    if (!this.focusedElement || this.focusedElement !== element) return;

    this.focusedElement = null;
    this._getElements().forEach((el) => {
      if (el.hasAttribute('aria-hidden-state')) {
        // Revert the aria-hidden state to the old state and remove aria-hidden-state attribute
        el.setAttribute('aria-hidden', el.getAttribute('aria-hidden-state'));
        el.removeAttribute('aria-hidden-state');
      } else {
        el.removeAttribute('aria-hidden');
      }
    });
  }
}
