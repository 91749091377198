const lineConfig = process.env.SEARCHV2_ENABLED === 'true' ? {
  np: 'https://store.playstation.com',
  mgmt: 'https://webstore.mgmt.playstation.com',
  'e1-np': 'https://webstore.e1-np.playstation.com',
} : {
  np: 'https://store.playstation.com',
  mgmt: 'https://store.mgmt.playstation.com',
  'e1-np': 'https://store.e1-np.playstation.com',
};

export default lineConfig;
