// TODO: revisit the inclusion of the Promise polyfill. For now it's needed to
// make the VSF integration work. Investigate why it is needed.
import 'core-js/es/promise';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import './objectAssign';
import './arrayFindIndex';

if (typeof window === 'object' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}

if (typeof window === 'object' && typeof window.requestIdleCallback !== 'function') {
  window.requestIdleCallback = callback => callback();
}
