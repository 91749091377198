/**
 * Serializes an object which allows it to be stored in the dom as an attribute
 *
 * @param {*} data
 * @returns {string | undefined} the serialized data
 */
export const serialize = data => JSON.stringify(data);

/**
 * Tries to read telemetry data associated with an HTML element via
 * the `data-telemetry` attribute
 *
 * @param {HTMLElement} el - the element to get telemetry data off of
 * @return {Object | null} the telemetry event attached to the element
 */
export const getTelemetryDataFrom = (el) => {
  let result;
  const data = el.dataset.telemetry;

  try {
    result = JSON.parse(data || 'null');
  } catch (e) {
    throw new Error(`could not parse telemetry data ${data} on element ${el}`);
  }

  return result;
};

/**
 * Responsible for maintaining the blueprints for all telemetry events
 */
export default class TelemetryDataFactory {
  /**
   * @private
   * @type {Object}
   */
  #specs = {};

  /**
   * @private
   * @type {string} event scope to limit capturing events
   *  if not associated with the tracking scope
   */
  #scope = '';

  constructor({ scope } = {}) {
    this.#scope = scope;
  }

  /**
   * Expands the types of events that can be created and hence reported on. Useful
   * for packing telemetry specs with lazy loaded modules
   *
   * @param {string} namespace - namespace to avoid collisions of specs
   * @param {Object} specs
   */
  addSpec(namespace, specs) {
    if (!namespace) throw new Error('cannot add spec without namespace');

    this.#specs = {
      ...this.#specs,
      [namespace]: {
        ...this.#specs[namespace],
        ...specs,
      },
    };
  }

  /**
   * Decorates the container data with a `telemetryData` property, with
   * telemetry side by side with the resource models, it can be attached to
   * a clickable element via a `data-telemetry` attribute to be read by
   * the click tracker
   *
   * @param {*} data - the data to decorate
   * @param {TelemetryMeta} meta - context for telemetry spec
   * @returns {*} the data passed in with telemetryData of given type if applicable
   */
  decorate(data, meta) {
    if (data instanceof Array) {
      return data.map(item => this.decorate(item, meta));
    }

    if (typeof data === 'object' && data !== null) {
      return { ...data, telemetryData: this.make({ data, ...meta }) };
    }

    console.warn('Data is not a container (e.g. Array or Object): could not decorate with telemetryData property');

    return data;
  }

  /**
   * Makes the telemetry event of the given type
   *
   * @param {TelemetryMeta} meta - context for telemetry spec
   * @returns {TelemetryEvent | boolean} returns false when event cannot be made
   */
  make(meta) {
    const { type = '' } = meta || {};
    const [namespace, typeId] = (type.match(/^([^:\\w]+):(.*)$/) || []).slice(1);
    const telemetrySpec = (this.#specs[namespace] || {})[typeId];

    if (!telemetrySpec) return false;

    const event = telemetrySpec.make(meta);

    if (!event) return false;

    const telemetryData = { ...event, scope: this.#scope };

    telemetryData.toString = () => serialize(telemetryData);

    return telemetryData;
  }
}
