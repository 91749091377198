/**
 * Remove undesirable characters from search term string
 * @param {string} term - string to sanitize
 * @returns {string} - the sanitized string
 * @see https://github.sie.sony.com/SIE/valkyrie-storefront/blob/master/app/services/susuwatari.js#L258
 */
export default (term) => {
  let sanitizedTerm = term;
  sanitizedTerm = sanitizedTerm.replace(/&[^;]*;/g, ''); // remove HTML entities
  sanitizedTerm = sanitizedTerm.replace(/["!@#$%^&*|+?.;:,/\\~`<>()[\]=-]/g, ' '); // Replace unwanted characters with spaces
  sanitizedTerm = sanitizedTerm.replace(/\s{2,}/g, ' '); // Compress excess whitespace
  sanitizedTerm = sanitizedTerm.toLowerCase();
  sanitizedTerm = sanitizedTerm.trim(); // Remove leading and trailing whitespace

  return sanitizedTerm;
};
