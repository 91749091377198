import { h, Component } from 'preact';
import bemNamespace from '../../utils/bemNamespace';

export const bem = bemNamespace('search-text-box');

export default class SearchTextBox extends Component {
  constructor(props) {
    super(props);

    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      this.base.firstChild.focus();
    }
  }

  handleKeyUp({ key }) {
    if (key === 'Enter') {
      const { onEnter, value } = this.props;
      onEnter(value);
    }
  }

  render({
    onInput = () => {},
    className = '',
    value = '',
    label = '',
    placeholder = '',
    children = null,
  }) {
    return (
      <div className={`${bem()} ${className}`}>
        <input
          className={bem('input')}
          value={value}
          aria-label={label}
          onInput={onInput}
          placeholder={placeholder}
          onKeyUp={this.handleKeyUp}
          type="search"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          data-qa="search-text-box"
        />
        { children }
      </div>
    );
  }
}
