/**
 * @typedef {Object} TelemetryMeta
 * @property {string} type - the type of event data
 * @property {*} [data] - a resource being tracked
 * @property {Object} [vars] - context variables required for event data
 */

/**
 * Click event when user selects a search filter option
 * @implements TelemetrySpec
 * @see https://confluence.sie.sony.com/display/SOC/Requirements+for+Jetstream+Telemetry#RequirementsforJetstreamTelemetry-E2BCaptureclickeventwhenuserselects%22Sites%22fromdropdown
 */
export default {
  type: 'click:search-filter',
  definitions: {
    searchFilters: {
      pdc: 'PlayStation.com',
      store: 'PlayStation Store',
    },
  },
  /**
   * Creates the event representing the selection of a search domain
   * @param {TelemetryMeta} meta
   * @param meta.data - a select option as used in Select component
   */
  make(meta) {
    const { data = {} } = meta || {};
    const { searchFilters } = this.definitions;

    return {
      type: 'ClickEvent',
      payload: {
        location: 'search',
        clickType: 'FILTER_CATEGORY',
        searchFilter: [searchFilters[data.value]],
        exitClick: false,
      },
    };
  },
};
