import regionAmericas from '@sie/shared-nav-ia-region-americas';
import regionEurope from '@sie/shared-nav-ia-region-europe';
import regionAsia from '@sie/shared-nav-ia-region-asia';
import regionJapan from '@sie/shared-nav-ia-region-japan';

export default {
  ...{ sonyBar: { link: 'https://www.sony.net' } },
  ...regionAmericas,
  ...regionEurope,
  ...regionAsia,
  ...regionJapan,
};
