import { h, Component } from 'preact';
import bemNamespace from '../../../../utils/bemNamespace';
import ThumbnailImage from '../ThumbnailImage';

export const bem = bemNamespace('tumbler-search-results-list-item');

export default class ListItem extends Component {
  handleLinkClick = (event) => {
    const { onLinkClick, product, searchPosition } = this.props;

    if (typeof onLinkClick === 'function') {
      onLinkClick(event,
        {
          productId: product.id,
          searchResult: product.name,
          searchPosition: searchPosition + 1,
        });
    }
  }

  render({ product, dataQa = null }) {
    return (
      <li className={bem()} data-qa={dataQa}>
        <a
          className={bem('anchor')}
          onClick={this.handleLinkClick}
          href={product.storeHref}
          data-telemetry={product.telemetryData}
        >
          <div className={bem('image-wrapper')}>
            <ThumbnailImage alt="" src={`${product.image}`} width={60} />
          </div>
          <div className={bem('details')}>
            <span className={bem('label-name')}>{ product.name }</span>
            <span className={bem('label-platforms')}>{ (product.platforms || []).join(', ')}</span>
          </div>
        </a>
      </li>
    );
  }
}
