import lines from './lines';
import sanitizeSearchTerm from './sanitizeSearchTerm';
import getTumblrSearchLocale from './getTumblerLocale';

const SEARCH_RESULT_SIZE = 5;
const SEARCH_PATH = '/store/api/chihiro/00_09_000/tumbler/';

/**
 * Constructs a tumbler search url based ont he provided parameters
 * @param {object} param0
 * @param {string} param0.language - PSN language code (e.g. en, es, ja)
 * @param {string} param0.country - PSN country code (e.g. us, mx, jp)
 * @param {string} param0.line - PSN line (e.g. e1-np, np)
 * @param {string} param0.searchTerm - Search Term
 * @param {string} param0.age - User's age
 * @returns {string} - tumbler search url
 */
const buildTumblerUrl = ({
  language, country, line, searchTerm, age,
}) => {
  const baseUrl = lines[line];
  if (!baseUrl) {
    throw new Error(
      `nav/search/tumbler/buildUrl: the specified line "${line}" does not exist. `
      + `avilable lines are "${Object.keys(lines).join(',')}"`,
    );
  }
  const {
    country: storeCountry,
    language: storeLanguage,
  } = getTumblrSearchLocale(language, country);
  const queryString = `size=${SEARCH_RESULT_SIZE}&suggested_size=${SEARCH_RESULT_SIZE}&mode=game`;
  return `${baseUrl}${SEARCH_PATH}${storeCountry.toUpperCase()}`
    + `/${storeLanguage.toLowerCase()}/${age}/${sanitizeSearchTerm(searchTerm)}`
    + `?${queryString}`;
};

export default buildTumblerUrl;
