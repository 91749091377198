/**
 * Click event when user selects a search result or see all link
 * @implements TelemetrySpec
 * @see https://confluence.sie.sony.com/display/SOC/Requirements+for+Jetstream+Telemetry#RequirementsforJetstreamTelemetry-E2CCaptureclickeventwhenuserselectsitemfromsearchresult
 */

const isStoreSite = () => {
  const { location: { host = '' } = {} } = window;
  return /^store(\..+)?\.playstation\.com/.test(host);
};

export default {
  type: 'click:search-result',
  /**
   *
   * @param {TelemetryMeta} meta
   * @param {Object} meta.data - a search result product
   * @param {Object} meta.vars - additional context
   * @param {string} meta.vars.searchTerm - the search term responsible for the result
   * @param {string} meta.vars.searchDomain - the search domain / filter used
   */
  make(meta) {
    const { data: product, vars = {} } = meta || {};
    const { searchTerm, searchDomain, isLinkHandled } = vars;

    const telemetryData = {
      location: 'search',
      clickType: 'search',
      clickMetadata: searchDomain,
      exitClick: true,
      searchTerm,
      hasPurchaseIntent: !isStoreSite(),
    };

    if (searchDomain === 'store') {
      if (product) {
        telemetryData.clickMetadata += ' single result';
      } else {
        telemetryData.clickMetadata += ' see all';
      }
    }

    if (product) {
      telemetryData.productName = product.name;
      telemetryData.productSku = product.sku;
    }

    return {
      type: 'ClickEvent',
      isLinkHandled,
      payload: telemetryData,
    };
  },
};
