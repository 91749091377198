// TODO: remove this once we stop supporting Starblaster
const toolbarOracle = {
  name: 'oracle',
  scriptId: 'oracle-main',
};

const toolbarStarblaster = {
  name: 'starblaster',
  scriptId: 'sb-main',
};

export default {
  ORACLE: toolbarOracle,
  STARBLASTER: toolbarStarblaster,
};
