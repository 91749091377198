import { isUsingEvidon, EvidonClient } from '@sie/web-common-react-components';
import getBrowserString from '../getBrowserString';
import getCookie from '../getCookie';
import getUserSignInStatusOracle from './getUserSignInStatusOracle';
import TOOLBAR from '../../constants/toolbar';

const config = {
  baseConfig: {
    env: {
      appName: 'jetstream',
      buildVersion: '0.0.0',
      charset: 'utf-8',
      deviceId: '',
      deviceType: '',
      signedIn: '',
    },
    plugins: {
      adobe: {
        reportSuite: ['snepsnsocial-prod'],
        dryRun: false,
      },
      clairvoyance: {
        env: 'np',
        eventUrl: 'https://eventcom.api.np.km.playstation.net/event/clairvoyance/',
        metricsMap: {},
      },
    },
    events: {
      Click: ['adobe'],
      LoadTime: ['clairvoyance'],
      PageView: ['adobe'],
      UserFacingError: ['adobe'],
    },
  },
  overrides: {
    criteria: {
      line: 'e1-np|q1-np|sp-int|qa2|euqa',
    },
    overrideConfig: {
      logLevel: 'debug',
      plugins: {
        adobe: {
          reportSuite: ['snepsnsocial-dev'],
        },
        clairvoyance: {
          env: 'e1-np',
          eventUrl: 'https://eventcom.api.e1-np.km.playstation.net/event/clairvoyance/',
          metricsMap: {},
        },
      },
    },
  },
};

const getGctPlatformPrivacyLevel = (async () => {
  let privacyPreference = 'minimal';
  if (!isUsingEvidon()) {
    const euCookiePreference = getCookie('eucookiepreference');
    if (!euCookiePreference || euCookiePreference === 'accept') {
      privacyPreference = 'all';
    }
  } else {
    await new EvidonClient().getPrivacyPreference().then((privacyPolicy) => {
      privacyPreference = privacyPolicy;
    });
    return privacyPreference;
  }
  return privacyPreference;
});

export default async function ({ DEVICES = {}, webToolbarType } = {}) {
  // match location with overrides
  const linePattern = new RegExp(config.overrides.criteria.line);
  const isDevEnv = window?.location.hostname.match(linePattern)?.length > 0;
  const gctConfig = isDevEnv ? Object.assign({}, config.baseConfig, config.overrides.overrideConfig)
    : config.baseConfig;

  gctConfig.env.buildVersion = process.env.JET_VERSION;
  gctConfig.env.deviceId = getBrowserString();
  gctConfig.env.platformPrivacyWs1 = await getGctPlatformPrivacyLevel();
  gctConfig.env.deviceType = DEVICES.WEB;
  gctConfig.plugins.clairvoyance.eventUrl += DEVICES.WEB;

  const {
    name: oracleName,
  } = TOOLBAR.ORACLE;

  if (webToolbarType === oracleName) {
    return new Promise((resolve) => {
      getUserSignInStatusOracle(webToolbarType)
        .then((isSignedIn) => {
          gctConfig.env.signedIn = isSignedIn;

          resolve(gctConfig);
        });
    });
  }

  if (window && window.sbWeb && window.sbWeb.isUserSignedIn) {
    return new Promise((resolve) => {
      window.sbWeb.isUserSignedIn((status) => {
        gctConfig.env.signedIn = status;

        resolve(gctConfig);
      });
    });
  }

  return Promise.resolve(gctConfig);
}
