import searchClassMap from './searchClassMap';

/**
 * Search factory is responsible for instantiating and returning a requested search strategy.
 */
export default class SearchFactory {
  /**
   * @type {Object}
   * @private
   */
  #config = {};

  #callbacks = {};

  /**
   * @param {Object} config - configuration properties for the search strategy.
   */
  constructor(config = {}) {
    this.#config = config;
  }

  setOnLinkClick(searchType, callback) {
    this.#callbacks[searchType] = callback;
  }

  /**
   * Creates an instance of a given search type.
   * @param {string}
   * @returns {Object}
   * @throws Error - when searchType is not supported
   */
  make(searchType) {
    const Cls = searchClassMap[searchType];

    if (!Cls) {
      throw new Error(
        'SearchFactory.make called with unknown search '
        + `type "${searchType}". Options are ${Object.keys(searchClassMap).join(', ')}`,
      );
    }

    return new Cls({ ...this.#config, onLinkClick: this.#callbacks[searchType] });
  }
}
