import e2a from './e2a-page-view';
import e2b from './e2b-click-search-filter';
import e2c from './e2c-click-search-result';
import tti from './tti';

export default {
  [e2a.type]: e2a,
  [e2b.type]: e2b,
  [e2c.type]: e2c,
  [tti.type]: tti,
};
