/**
 * The public telemetry interface used in an application
 */
export default class TelemetryFacade {
  #buffer = null;

  #dataFactory = null;

  #locator = null;

  constructor({ buffer, dataFactory, locator }) {
    this.#buffer = buffer;
    this.#dataFactory = dataFactory;
    this.#locator = locator;
  }

  /**
   * Adds a locator that tests whether the given section is the currently active location
   * @param {string} sectionName - the section name to resolve with if active
   * @param {Function} locator - the locator will get the sectionName as an argument
   */
  addLocator(sectionName, locator) {
    this.#locator.addLocator(sectionName, locator);
  }

  /**
   * Gets the GCT location and locationType fields identifying which app and section
   * the user is on currently
   * @return {Object} with properties `location` and `locationType`;
   */
  getCurrentLocation() {
    return this.#locator.resolveLocation(this.getCurrentSection());
  }

  /**
   * Gets the current section that the user is on as defined in the telemetry spec
   * @return {string}
   */
  getCurrentSection() {
    return this.#locator.getCurrentSection();
  }

  /**
   * Returns the global telemetry location (same as GCT location property)
   *
   * @see https://github.sie.sony.com/SIE/grand-central-telemetry/blob/6d06775eac2276b01700e04a3428d0cdec02e4ad/documentation/03_location_identification.md
   * @param {string} feature - name of the feature identified in the location
   * @return {string} the full telemetry location
   */
  getLocation(feature) {
    return this.#locator.resolveLocation(feature).location;
  }

  /**
   * Captures an event for reporting via GCT or similar
   *
   * @param {TelemetryEvent} event - telemetry event to report
   * @see TelemetryBuffer
   */
  capture(event) {
    this.#buffer.addEvent(event);
  }

  /**
   * Sets the telemetry reporter to begin the flow of events over the network
   *
   * @param {TelemetryReporter} reporter - telemetry reporter instance
   * @see TelemetryBuffer
   */
  setReporter(reporter) {
    this.#buffer.setReporter(reporter);
  }

  /**
   * Extends what the buffer can track. The callback passed in will receive a reference
   * to the handleEvent callback to let the buffer know when an event has occurred.
   * @param  {Function} track - callback which implements a tracking strategy
   * @return {Function} return result of calling track should be a function to stop
   *   the tracking that was started
   * @example
   * function trackFoo(telemetry) {
   *   window.addEventListener('foo', function (event) {
   *     telemetry.capture(event.data);
   *   }, false);
   * }
   *
   * telemetry.start(trackFoo);
   */
  start(track) {
    if (typeof track !== 'function') throw new Error('could not start tracking: track is not a function');

    return track(this);
  }

  /**
   * Expands the types of events that can be created and hence reported on. Useful
   * for packing telemetry specs with lazy loaded modules
   *
   * @param {string} namespace - namespace to avoid collisions of specs
   * @param {Object} specs
   * @see TelemetryDataFactory
   */
  addSpec(namespace, specs) {
    this.#dataFactory.addSpec(namespace, specs);
  }

  /**
   * Decorates the container data with a `telemetryData` property
   *
   * @param {*} data - the data to decorate
   * @param {TelemetryMeta} meta - context for telemetry spec
   * @returns {Object} with curried function taking the event factory meta context
   * @see TelemetryDataFactory
   * @example
   * const data = decorate([
   *  { productId: 'blah' }
   * ]).withEvent({ type: 'search:click:foo' });
   *
   * // Will have telemetryData property when blueprint for event of type was found
   * data.telemetryData === { type: 'ClickEvent', ...and, ...others };// true
   */
  decorate(data) {
    return { withEvent: meta => this.#dataFactory.decorate(data, meta) };
  }

  /**
   * Makes the telemetry event with a specific spec
   * blueprint given the type
   *
   * @param {TelemetryMeta} meta - context for telemetry spec
   * @returns {TelemetryEvent}
   * @see TelemetryDataFactory
   */
  makeEvent(meta) {
    return this.#dataFactory.make(meta);
  }
}
