/**
 * Responsible for Telemetry related location identifiers
 */
export default class TelemetryLocator {
  /**
   * @private
   * @type {Function | null} application specific location resolver
   */
  #resolveLocation = null;

  /**
   * @private
   * @type {Object} map of registered locators
   */
  #locators = {};

  constructor({ resolveLocation }) {
    if (typeof resolveLocation !== 'function') {
      throw new Error('resolveLocation must be a function');
    }

    this.#resolveLocation = resolveLocation;
  }

  /**
   * Adds a location locator for a section or list of sections
   * @param {string | Array} name the name(s) of the section(s) resolved by the locator
   * @param {Function} locator - function returns location identifier if section is active or false
   */
  addLocator(name, locator) {
    if (typeof locator === 'function') {
      this.#locators[name] = locator;
    }
  }

  /**
   * Exposes the application specific resolveLocation method
   * @param {string} section - the section the user is active currently
   * @return {Object} with `location` and `locationType` properties defined in GCT docs
   * @see https://github.sie.sony.com/SIE/grand-central-telemetry/blob/6d06775eac2276b01700e04a3428d0cdec02e4ad/documentation/03_location_identification.md
   */
  resolveLocation(section) {
    return this.#resolveLocation(section);
  }

  /**
   * Uses the registered locators to resolve the currently active section
   * @return {String} currently active section or empty string if section could not be resolved
   */
  getCurrentSection() {
    const sections = Object.keys(this.#locators);

    for (let i = 0; i < sections.length; i += 1) {
      const sectionKey = sections[i];
      const sectionName = this.#locators[sectionKey](sectionKey);

      if (sectionName) return sectionName;
    }

    return '';
  }
}
