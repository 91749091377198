import generateElementId from '../utils/generateElementId';
import getQueryParam from '../utils/getQueryParam';
import kebabCase from '../utils/kebabCase';
import { encryptOnlineId } from '../utils/onlineIdInUrl';
import { storeToPdcMapping } from '../utils/l10n';

const anchorContent = (anchorData) => {
  const { iconId } = anchorData;

  return iconId ? `<span class="shared-nav-icon-text">${anchorData.label}</span>` : anchorData.label;
};

const anchorClass = (anchorData, type) => {
  const { mobileNavIcon, iconId } = anchorData;
  let classes = `shared-nav__${type}-anchor shared-nav-anchor dtm-no-track `;

  if (mobileNavIcon) {
    classes += `shared-nav-icon shared-nav-mobile-icon shared-nav-icon--${mobileNavIcon} `;
  }

  if (iconId) {
    classes += `shared-nav-link-icon shared-nav-link-icon--${iconId}`;
  }

  if (type === 'link-list') {
    classes += 'shared-nav-icon shared-nav-icon--tertiary-marker';
  }

  return classes;
};

const createSMCID = ({ hostname, pathname }, { anchorData, locale, section }) => {
  const filteredPathname = encryptOnlineId(pathname);
  const pagename = (filteredPathname !== '/') ? filteredPathname.split('/').filter(entry => entry !== '') : [];

  let formattedPagename = '';
  let hostapp = 'other';

  if (hostname === 'my.playstation.com') {
    hostapp = 'social';
  } else if (hostname === 'store.playstation.com') {
    hostapp = 'store';
  } else if (hostname.indexOf('.playstation.com') !== -1) {
    hostapp = 'pdc';
  }

  if (pagename[0] && pagename[0].toLowerCase() === locale) {
    pagename.shift();
  }

  if (pagename.length) {
    formattedPagename = `:${pagename.join('-')}`;
  }

  return `${hostapp}:${locale}${formattedPagename}:primary nav:${kebabCase(section)}:${kebabCase(anchorData.label)}`;
};

const replaceLocaleInPath = (url, locale) => {
  const localeRegex = /\/[A-Za-z]{2,4}([_-]([A-Za-z]{4}|[0-9]{3}))?([_-]([A-Za-z]{2}|[0-9]{3}))\/?/;
  const match = url.match(localeRegex);
  let mappedUrl = url;

  if (match) {
    mappedUrl = mappedUrl.replace(localeRegex, `/${locale}/`);
  }

  return mappedUrl;
};

export default (
  { location = {} },
  {
    locale,
    baseUrl,
    anchorData,
    section,
    type,
    indexedId,
  },
) => {
  const { link, stringId, newWindow } = anchorData;
  const smcid = createSMCID(location, {
    anchorData,
    locale,
    section,
  });
  const emcid = getQueryParam(location.search, 'emcid');
  const l2StringId = indexedId || `${section}_${stringId}`;
  const id = generateElementId(`link-${type}`, l2StringId);
  const queryParams = [];
  let anchorLink = (link.indexOf('http') !== 0) ? baseUrl + link : link;
  let labelData = `${anchorContent(anchorData)}`;

  // Rewrite store and help links
  // if locale is a key in store to pdc mapping, do this
  Object.keys(storeToPdcMapping).forEach((storeLocale) => {
    if (locale === storeLocale) {
      if (anchorLink.indexOf('store.playstation.com') !== -1
        || anchorLink.indexOf('status.playstation.com') !== -1) {
        anchorLink = replaceLocaleInPath(anchorLink, locale);
      }
    }
  });

  if (smcid) {
    queryParams.push(`smcid=${encodeURIComponent(smcid)}`);
  }

  if (emcid) {
    queryParams.push(`emcid=${encodeURIComponent(emcid)}`);
  }

  if (queryParams.length && stringId !== 'msg_hardware_and_discs') {
    anchorLink += (anchorLink.indexOf('?') !== -1) ? '&' : '?';
    anchorLink += queryParams.join('&');
  }

  if (type === 'link-list') {
    labelData = `<span>${anchorContent(anchorData)}</span>`;
  }

  return `
    <a id="${id}" class="${anchorClass(anchorData, type)}" href="${anchorLink}" ${(newWindow) ? 'target="_blank"' : ''}>
      ${labelData}
    </a>
  `;
};
