/**
 * This class is responsible to control whether we should display a focus ring on focused element.
 * Background:
 * Google chrome browser will persist the focus ring on a button when it is clicked,
 * which might not be desirable.
 * On the other hand, simply setting outline: 0 is also bad from accessibility perspective.
 * This class "fixes" this issue by listening to both the keydown/mousedown events.
 * It will set a flag that indicates whether we are in keyboard mode or not.
 * On keyboard mode, it adds a CSS class to the scoped element.
 * We can then use this CSS class to control whether we displays focus ring or not.
 * The incoming CSS4 focus-visible pseudo-class will fix this issue. Until all browsers
 * have this supports, we are stucked with this type of "workaround" :(
 */
export default class FocusRingHandler {
  _inKeyboardMode = false;

  _scope = null;

  _handleKeydownEvent = () => {
    if (!this._inKeyboardMode) {
      this._inKeyboardMode = true;
      this._toggleKeyboardModeClass();
    }
  }

  _handleMouseDownEvent = () => {
    if (this._inKeyboardMode) {
      this._inKeyboardMode = false;
      this._toggleKeyboardModeClass();
    }
  }

  _toggleKeyboardModeClass = () => {
    if (this._scope && this._scope.classList) {
      if (this._inKeyboardMode) {
        this._scope.classList.add('shared-nav--keyboard-mode');
      } else {
        this._scope.classList.remove('shared-nav--keyboard-mode');
      }
    }
  }

  setScope(scope = document.body) {
    this._scope = scope;
  }

  init() {
    if (this._scope) {
      this._scope.addEventListener('keydown', this._handleKeydownEvent);
      this._scope.addEventListener('mousedown', this._handleMouseDownEvent);
    }
  }

  destroy() {
    if (this._scope) {
      this._scope.removeEventListener('keydown', this._handleKeydownEvent);
      this._scope.removeEventListener('mousedown', this._handleMouseDownEvent);
    }
  }
}

let focusRingHandlerInstance = null;

export const setFocusRingHandler = (scope) => {
  if (!focusRingHandlerInstance) {
    focusRingHandlerInstance = new FocusRingHandler();
  }
  focusRingHandlerInstance.setScope(scope);
  focusRingHandlerInstance.init();
};
