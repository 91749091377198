import { h } from 'preact';
import ListItem from './components/ListItem';
import bemNamespace from '../../utils/bemNamespace';

export const bem = bemNamespace('tumbler-search-results');

export default ({
  onLinkClick,
  seeAllUrl = '',
  seeAllUrlTelemetryData,
  products = [],
  l10n = () => (''),
}) => (
  <div
    aria-live="polite"
    className={bem()}
  >
    {
      products.length > 0
      && (
        <div className={bem('container', 'results')}>
          <ul className={bem('list')}>
            { products.map((product, index) => (
              <ListItem
                dataQa={`search-result-${index}`}
                product={product}
                searchPosition={index}
                onLinkClick={onLinkClick}
              />
            ))
            }
          </ul>
          <div className={bem('see-all')}>
            {
              seeAllUrl
              && (
                <a
                  onClick={onLinkClick}
                  href={seeAllUrl}
                  className={bem('see-all-link')}
                  data-qa="see-all-link"
                  data-telemetry={seeAllUrlTelemetryData}
                >
                  {l10n('msg_see_all')}
                </a>
              )
            }
          </div>
        </div>
      )
    }
  </div>
);
