// https://stackoverflow.com/questions/10730362/get-cookie-by-name
export default function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  let cookieValue;

  if (parts.length === 2) {
    cookieValue = parts.pop().split(';').shift();
  }
  return cookieValue;
}
