import escapeHtml from 'escape-html';
import l10n from '../utils/l10n';

export default (locale, isSSR = false, {
  barebones = false,
  disableLogoLink = false,
  disableSearch = false,
}) => {
  const getLocalizedString = l10n(locale);

  const logoSvg = `
    <svg
      aria-hidden="true"
      focusable="false"
      class="shared-nav-ps-logo"
      width="50px"
      height="50px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 50 50"
    >
      <g>
        <g>
          <path d="M5.8,32.1C4.3,33.1,4.8,35,8,35.9c3.3,1.1,6.9,1.4,10.4,0.8c0.2,0,0.4-0.1,0.5-0.1v-3.4l-3.4,1.1
       c-1.3,0.4-2.6,0.5-3.9,0.2c-1-0.3-0.8-0.9,0.4-1.4l6.9-2.4V27l-9.6,3.3C8.1,30.7,6.9,31.3,5.8,32.1z M29,17.1v9.7
       c4.1,2,7.3,0,7.3-5.2c0-5.3-1.9-7.7-7.4-9.6C26,11,23,10.1,20,9.5v28.9l7,2.1V16.2c0-1.1,0-1.9,0.8-1.6C28.9,14.9,29,16,29,17.1z
        M42,29.8c-2.9-1-6-1.4-9-1.1c-1.6,0.1-3.1,0.5-4.5,1l-0.3,0.1v3.9l6.5-2.4c1.3-0.4,2.6-0.5,3.9-0.2c1,0.3,0.8,0.9-0.4,1.4
       l-10,3.7V40L42,34.9c1-0.4,1.9-0.9,2.7-1.7C45.4,32.2,45.1,30.8,42,29.8z" fill="#0070d1"/>
        </g>
      </g>
    </svg>
  `;

  const logoContent = disableLogoLink ? logoSvg
    : `<a class="shared-nav-ps-logo-link dtm-no-track" href="https://www.playstation.com/${locale}" aria-label="${getLocalizedString('msg_playstation_dot_com')}">
        ${logoSvg}
      </a>`;

  const logo = `<span class="shared-nav__ps-logo-container">${logoContent}</span>`;

  const hamburger = barebones ? ''
    : `
      <button class="shared-nav-icon shared-nav-icon--hamburger shared-nav-hamburger dtm-no-track"
        aria-label="${getLocalizedString('msg_open_menu')}" aria-haspopup="true" aria-expanded="false">
      </button>    
    `;

  const search = disableSearch ? ''
    : `
      <span class="shared-nav-search-container">
        <button class="shared-nav-icon shared-nav-icon--search shared-nav-search dtm-no-track" data-qa="shared-nav-search-button">
          <span class="shared-nav-search__label">${escapeHtml(getLocalizedString('msg_search'))}</span>
          <svg
            aria-hidden="true"
            focusable="false"
            width="50px"
            height="50px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 50"
          >
            <g>
              <path d="M8,20.913 C8,14.344 13.344,9 19.913,9 C26.482,9 31.827,14.344 31.827,20.913 C31.827,27.482 26.482,32.827 19.913,32.827 C13.344,32.827 8,27.482 8,20.913 M45.112,43.585 L32.346,30.82 C34.518,28.099 35.827,24.658 35.827,20.913 C35.827,12.139 28.688,5 19.913,5 C11.139,5 4,12.139 4,20.913 C4,29.688 11.139,36.827 19.913,36.827 C23.503,36.827 26.808,35.618 29.474,33.604 L42.284,46.413 C42.674,46.804 43.186,46.999 43.698,46.999 C44.209,46.999 44.721,46.804 45.112,46.413 C45.502,46.023 45.698,45.511 45.698,44.999 C45.698,44.488 45.502,43.976 45.112,43.585"></path>
            </g>
          </svg>
        </button>
      </span>
    `;

  return `
    <div>
      <nav class="shared-nav shared-nav--menu-closed" ${isSSR ? 'data-jetstream-ssr-nav-container="true"' : ''}>
        ${logo}
        <div class="shared-nav__primary-parent">
        </div>
      </nav>
      <span class="shared-nav__ctas-container">
        ${hamburger}
        ${search}
      </span>
    </div>
  `;
};
