import lines from './lines';
import getQueryParam from '../../../../utils/getQueryParam';
import getTumblrSearchLocale from './getTumblerLocale';

/**
 * Constructs a store resolve url based ont he provided parameters
 * @param {object} param0
 * @param {string} param0.language  - PSN language code (e.g. en, es, ja)
 * @param {string} param0.country   - PSN country code (e.g. us, mx, jp)
 * @param {string} param0.line      - PSN line (e.g. e1-np, np)
 * @param {string} param0.productId - Catalog product id
 * @returns {string} - tumbler search url
 */
const buildStoreResolveUrl = ({
  country = '',
  script,
  language = '',
  line = '',
  productId = '',
  smcid = '',
}) => {
  const emcid = getQueryParam(window.location.search, 'emcid');
  const baseUrl = lines[line];
  if (!baseUrl) {
    throw new Error(
      `nav/search/tumbler/buildStoreUrl: the specified line "${line}" does not exist. `
      + `avilable lines are "${Object.keys(lines).join(',')}"`,
    );
  }

  const {
    country: storeCountry,
    language: storeLanguage,
  } = getTumblrSearchLocale(language, country);
  const locale = `${storeLanguage.toLowerCase()}${script ? `-${script}` : ''}-${storeCountry.toLowerCase()}`;

  let storeResolveUrl = `${baseUrl}/${locale}`
    + `/?resolve=${encodeURIComponent(productId)}&smcid=${encodeURIComponent(smcid)}`;

  if (emcid) {
    storeResolveUrl += `&emcid=${encodeURIComponent(emcid)}`;
  }

  return storeResolveUrl;
};

export default buildStoreResolveUrl;
