import getPathname from './getPathname';

const md5 = require('blueimp-md5');

// The functionality contained in this file is for hiding onlineIds in our reports
export function getOnlineIdFromUrlPathname(pathname) {
  const blackhawkProfilePath = /\/(?:profile)\/([^\/]+)(?:\/.*|\/?)/.exec(pathname) || []; // eslint-disable-line no-useless-escape
  const storeWishListPath = /\/(?:wishlist\/friends)\/([^\/]+)(?:\/.*|\/?)/.exec(pathname) || []; // eslint-disable-line no-useless-escape

  return blackhawkProfilePath[1] || storeWishListPath[1];
}

export function encryptOnlineId(pathname, locationProperty) {
  const onlineId = getOnlineIdFromUrlPathname(pathname);
  const returnString = locationProperty || pathname;

  if (onlineId) {
    return returnString.replace(onlineId, md5(onlineId));
  }

  return returnString;
}

export function encryptOnlineIdInUrl(url) {
  const pathname = getPathname(url);

  return encryptOnlineId(pathname, url);
}

export default {
  encryptOnlineId,
  encryptOnlineIdInUrl,
  getOnlineIdFromUrlPathname,
};
