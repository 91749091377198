import findTabbable, { findTabbableBoundaries } from './elementStateHelpers';
import keyCodes from './keyboardEventCodes';

const isActive = el => el === document.activeElement;

export default class FocusTrapper {
  constructor(rootElement) {
    this.rootElement = rootElement;
    this._handleKeydown = this._handleKeydown.bind(this);
  }

  _handleKeydown(event) {
    const { keyCode, shiftKey } = event;
    const tabbable = findTabbableBoundaries(this.rootElement);

    if (typeof this.onEscape === 'function' && keyCode === keyCodes.ESCAPE) {
      this.onEscape();
      event.stopPropagation();
    }

    if (!tabbable || keyCode !== keyCodes.TAB) return;

    if (!shiftKey && isActive(tabbable.last)) {
      tabbable.first.focus();
      event.preventDefault();
    }

    if (shiftKey && isActive(tabbable.first)) {
      tabbable.last.focus();
      event.preventDefault();
    }
  }

  setOnEscape(onEscape) {
    this.onEscape = onEscape;
  }

  _ensureFocusInTrap() {
    const tabbable = findTabbable(this.rootElement);
    const firstTabbable = tabbable[0];
    const isNotInTrap = tabbable.filter(isActive).length === 0;

    if (isNotInTrap && firstTabbable) {
      firstTabbable.focus();
    } else if (isNotInTrap) {
      this.rootTabIndex = this.rootElement.getAttribute('tabindex');
      this.rootElement.setAttribute('tabindex', this.rootTabIndex || 0);
      this.rootElement.focus();
    }
  }

  trap() {
    if (!this.rootElement) {
      throw new Error('no root element provided to trap focus');
    }

    this._ensureFocusInTrap();
    this.rootElement.addEventListener('keydown', this._handleKeydown);
  }

  release() {
    this.rootElement.removeEventListener('keydown', this._handleKeydown);
    this.rootElement.setAttribute('tabindex', this.rootTabIndex);
    this.rootElement = null;
  }
}
