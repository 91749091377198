/**
 * @typedef {Object} LocaleComponents
 * @property {string} language - language code (en - English)
 * @property {string} script - script code (hant - Traditional Chinese)
 * @property {string} country - region/country code (br - Brazil)
 */

/**
 * Parses a locale string into component parts.
 * @param {String} locale - 2 part or 3 part locale code
 * @returns {LocaleComponents}
 */
export default (locale) => {
  const localeComponents = locale.split('-');
  let language;
  let script;
  let country;

  if (localeComponents.length === 3) {
    [language, script, country] = localeComponents;
  } else {
    [language, country] = localeComponents;
  }

  return {
    language,
    script,
    country,
  };
};
