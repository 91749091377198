/**
 * Shared click away context to allow control over event propagation
 */
export default class ClickAwayContext {
  #elements = [];

  #isPropagationStopped = false;

  #handleClickOutside = (event) => {
    this.#elements.forEach((el) => {
      if (this.#isPropagationStopped) return;

      if (!el.node.contains(event.target) && typeof el.onClickAway === 'function') {
        el.onClickAway({
          stopPropagation: () => { this.#isPropagationStopped = true; },
        });
      }
    });

    this.#isPropagationStopped = false;
  }

  init() {
    document.addEventListener('mousedown', this.#handleClickOutside);
    document.addEventListener('touchstart', this.#handleClickOutside);
  }

  destroy() {
    document.removeEventListener('mousedown', this.#handleClickOutside);
    document.removeEventListener('touchstart', this.#handleClickOutside);
  }

  add(el) {
    const removeIndex = this.#elements.push(el) - 1;

    return () => this.#elements.splice(removeIndex, 1);
  }
}

let clickAwayInstance = null;

/**
 * Singleton click away context
 */
export const clickAway = () => {
  if (clickAwayInstance) return clickAwayInstance;

  clickAwayInstance = new ClickAwayContext();
  clickAwayInstance.init();

  return clickAwayInstance;
};
