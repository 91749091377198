import getQueryParam from './utils/getQueryParam';
import initTelemetry from './utils/telemetry';
import navTelemetrySpecs from './utils/telemetry/specs/nav';

const getIdForTelemetry = (element) => {
  const elementId = element.id;
  const stringIdMarker = 'msg-';

  return elementId.slice(elementId.indexOf(stringIdMarker) + stringIdMarker.length);
};

const addTelemetryDataToSecondary = (parentEl, category, telemetry) => {
  const secondaryLinks = [].slice.call(parentEl.querySelectorAll('.shared-nav__secondary-anchor'));

  secondaryLinks.forEach((secondaryLink) => {
    const linkName = getIdForTelemetry(secondaryLink);

    const data = {
      category,
      linkName,
    };

    secondaryLink.setAttribute('data-telemetry', telemetry.makeEvent({ type: 'shared-nav:click:nav-item', data }));
  });

  const quickLinks = [].slice.call(parentEl.querySelectorAll('.shared-nav__link-list-anchor'));

  quickLinks.forEach((quickLink) => {
    const linkName = quickLink.innerText.replace(/\W/g, '');

    const data = {
      category,
      linkName,
    };

    quickLink.setAttribute('data-telemetry', telemetry.makeEvent({ type: 'shared-nav:click:nav-item', data }));
  });
};

const addTelemetryDataToPrimary = (telemetry) => {
  const { document } = window;
  const primaryNavDropdownControls = [].slice.call(document.querySelectorAll('.shared-nav__primary-button'));
  const referrer = getQueryParam(window.location.search, 'smcid');
  const marketingCampaign = getQueryParam(window.location.search, 'emcid');

  primaryNavDropdownControls.forEach((primaryNav) => {
    const category = getIdForTelemetry(primaryNav);
    const data = {
      category,
      marketingCampaign,
      referrer,
    };

    if (primaryNav.tagName.toLowerCase() === 'button') {
      primaryNav.setAttribute('data-telemetry', telemetry.makeEvent({ type: 'shared-nav:page-view', data }));
    }

    if (primaryNav.tagName.toLowerCase() === 'a') {
      data.linkName = category;
      primaryNav.setAttribute('data-telemetry', telemetry.makeEvent({ type: 'shared-nav:click:nav-item', data }));
    }

    addTelemetryDataToSecondary(primaryNav.parentNode, category, telemetry);
  });
};

const addTelemetryDataToNav = (telemetry) => {
  addTelemetryDataToPrimary(telemetry);
};

/**
 * Sets up the telemetry system for use with jetstream (shared nav)
 */
export default (webToolbarType) => {
  const telemetry = initTelemetry({
    scope: 'shared-nav',
    resolveLocation: section => ({
      location: `web:psn:primary${section ? ` nav:${section}` : ''}`,
      locationType: 'web primary navigation',
    }),
  });

  telemetry.addSpec('shared-nav', navTelemetrySpecs);
  telemetry.privacyPolicy = '';
  addTelemetryDataToNav(telemetry);

  requestIdleCallback(() => {
    import('./utils/gct')
      .then(({ default: initGct }) => {
        const setupGCT = () => (initGct(telemetry, webToolbarType));
        setupGCT();

        // Reset telemetry on consent change
        window.document.addEventListener('evidonConsentState', setupGCT);
      });
  }, { timeout: 5000 });

  return telemetry;
};
