/**
 * Shared Nav Search TTI
 * Measures from click of search button on shared nav primary to the mount / render
 * of the search modules' container
 * @implements TelemetrySpec
 */
export default {
  type: 'tti',
  definitions: {
    clairvoyanceMetrics: {
      // TODO: register Clairvoyance metrics
      'search:tti': 'TBA',
    },
  },
  /**
   * Creates the event representing the selection of a search domain
   * @param {TelemetryMeta} meta
   * @param meta.data - a select option as used in Select component
   */
  make(meta) {
    const { vars = {} } = meta || {};
    const { timings } = vars;
    const { clairvoyanceMetrics } = this.definitions;

    const relevantTimings = (timings || []).filter(t => clairvoyanceMetrics[t.name]);

    if (!timings || relevantTimings.length === 0) {
      console.warn('timings was not defined or empty, TTI not recorded. ensure clairvoyance metrics are defined and entry marks match');
      return false;
    }

    if (this.isLocked) return false;

    this.isLocked = true;

    return {
      type: 'LoadTimeEvent',
      payload: {
        timings: relevantTimings,
      },
    };
  },
};
