import {
  TelemetryService,
  DEVICES,
  PageViewEvent,
  LoadTimeEvent,
  ClickEvent,
} from '@sie/grand-central-telemetry';
import AnalyticsReporter from './AnalyticsReporter';
import createGctConfig from './createGctConfig';

const PRIVACY_ALLOWED_VALUES = ['exempt', 'minimal', 'all'];

/**
 * Entry point for initializing GCT
 */
const initGct = (telemetry, webToolbarType) => (
  createGctConfig({ DEVICES, webToolbarType })
    .then((gctConfig) => {
      // Only reinit if privacy policy has changed
      const privacyPolicy = gctConfig?.env?.platformPrivacyWs1;
      if (telemetry.privacyPolicy !== privacyPolicy
        && PRIVACY_ALLOWED_VALUES.includes(privacyPolicy)) {
        // eslint-disable-next-line no-param-reassign
        telemetry.privacyPolicy = privacyPolicy;
        const gct = new TelemetryService(gctConfig);
        const reporter = new AnalyticsReporter(gct, {
          PageViewEvent,
          LoadTimeEvent,
          ClickEvent,
        });

        window.sharedNav = window.sharedNav || {};
        window.sharedNav.telemetry = gct;

        telemetry.setReporter(reporter);
      }
    })
);

export default initGct;
