const MS_PER_FRAME = 17;

/**
 * Interface for classes that represent an animation
 * @interface Animation
 */

/**
 * Starts the animation
 * @function
 * @name Animation#start
 * @returns {Animation} reference to the started animation
 */

/**
 * Determines if the animation is running or not
 * @function
 * @name Animation#isAnimating
 * @returns {boolean}
 */

/**
  * Cancels an animation, leaving the element in it's last position
  * @function
  * @name Animation#cancel
  */

export const nullAnimation = { start() { return this; }, cancel() {}, isAnimating() {} };

/**
 * Generates linear increases in a value given a start, end point and duration
 * @implements Iterator
 * @param {Object} options
 * @param {Number} options.from - number to start from
 * @param {Number} options.to - number to end at
 * @param {Number} options.duration - number of milliseconds to complete progression
 *  from start to end assuming 60 fps motion
 */
class LinearEase {
  /**
   * @type {Number} - the step size
   */
  #step = 0;

  /**
   * @type {Number} - the numerical direction of the step
   */
  #direction = 1;

  /**
   * @type {Number} - the current value
   */
  #current = 0;

  /**
   * @type {Number} - the destination value
   */
  #final = 0;

  constructor({ from, to, duration }) {
    this.#step = ((to - from) * MS_PER_FRAME) / duration;
    this.#direction = this.#step / Math.abs(this.#step);
    this.#current = from;
    this.#final = to;
  }

  next() {
    this.#current += this.#step;
    const isDone = this.#direction === -1
      ? this.#current <= this.#final
      : this.#current >= this.#final;

    return { value: isDone ? this.#final : this.#current, done: isDone };
  }
}

// TODO: move boiler plate animation code into a class or other reusable hunk
//  if another javascript animation is required

/**
 * Slides an element along the y axis by a specified amount
 * @param {HTMLElement} el - the element to animate
 * @param {Object} options
 * @param {Number} options.from - the starting value in pixels
 * @param {Number} options.to - the end value in pixels
 * @returns {Animation}
 */
export const slideY = (el, {
  from,
  to,
  delay = 0,
  duration = 300,
}) => ({
  ease: new LinearEase({ from, to, duration }),
  requestId: null,
  isAnimating() {
    return this._isAnimating;
  },
  start() {
    let start;
    this._isAnimating = true;

    const slideStep = (timestamp) => {
      start = start || timestamp;

      if (timestamp - start < delay) {
        this.requestId = window.requestAnimationFrame(slideStep);
        return;
      }

      const { value, done } = this.ease.next();

      el.style.transform = `translateY(${value}px)`;// eslint-disable-line

      if (!done) {
        this.requestId = window.requestAnimationFrame(slideStep);
      } else {
        this._isAnimating = false;
      }
    };

    window.requestAnimationFrame(slideStep);

    return this;
  },
  cancel() {
    this._isAnimating = false;
    window.cancelAnimationFrame(this.requestId);
  },
});

/**
 * Determines if a thing is an animation
 * @param {*} subject - any javascript data type
 * @returns {boolean} whether it conforms to the animation interface
 */
export const isAnimation = subject => (!!subject
    && typeof subject.start === 'function'
    && typeof subject.isAnimating === 'function'
    && typeof subject.cancel === 'function'
);

/**
 * Starts all animations together
 * @param  {...Animations} animations - n number of animations to group together
 * @returns {Animation} composite animation
 */
export const animateTogether = (...possibleAnimations) => {
  const animations = possibleAnimations.filter(isAnimation);

  return {
    start() {
      animations.map(a => a.start());

      return this;
    },
    cancel() {
      animations.map(a => a.cancel());
    },
    isAnimating() {
      return animations.reduce((acc, a) => acc || a.isAnimating(), false);
    },
  };
};
