import { encryptOnlineIdInUrl } from '../onlineIdInUrl';

/**
 * @typedef TelemetryBuffer
 * @property scope - the event scope, what micro frontend does the event belong to
 * @property type - the type of event, this should be the same as the
 *  GCT event class name
 * @property [subtype] - used for adding additional categories to an event
 * @property payload - the data used to construct the GCT event with
 */

/**
 * @class Responsible for capturing telemetry events for future reporting
 */
export default class TelemetryBuffer {
  /**
   * @private
   * @type {TelemetryReporter | null} - object responsible for reporting telemetry events
   */
  #reporter = null;

  /**
   * @private
   * @type {TelemetryLocator | null} - resolves application specific telemetry locations
   */
  #locator = null;

  /**
   * @private
   * @type {Array.<TelemetryEvent>} - events captured before reporter is available
   */
  #events = [];

  /**
   * @private
   * @type {Promise | null} - async state when events are flushed and sent out via
   *  reporter
   */
  #processingPromise = null;

  /**
   * @private
   * @type {Object} - collection of callbacks registered under the same name as
   *  event type. For example:
   *  ```
   *  {
   *    ClickEvent: handlerFn
   *  }
   *  ```
   */
  #callbacks = {};

  /**
   * @param  {TelemetryReporter=} reporter - the reporter to use
   */
  constructor({ reporter, locator }) {
    this.#reporter = reporter;
    this.#locator = locator;
  }

  /**
   * Getter for events that have been tracked and stored to date
   * @return {Array.<TelemetryEvent>} The events that have been tracked to the moment
   */
  getStoredEvents() {
    return this.#events;
  }

  /**
   * Setter for reporter. Once the reporter is set the buffer will delegate events
   * as they occur to the reporter to report. Events that are successfully dispatched will
   * be removed from the buffer.
   * @return {Promise} A promise representing the batch dispatch of stored events
   */
  setReporter(reporter) {
    this.#reporter = reporter;

    if (this.#processingPromise) {
      return this.#processingPromise;
    }

    this.#processingPromise = this.#processStoredEvents()
      .then(() => { this.#processingPromise = null; });

    return this.#processingPromise;
  }

  #processStoredEvents = () => {
    const promises = this.#events.map(this.#processEvent.bind(this));

    return Promise.all(promises);
  }

  /**
   * Allows events to be added to the buffer to be stored or processed and reported.
   * @param  {Object} event -
   *
   * @example
   * buffer.addEvent({
   *   type: 'type',
   *   subtype: 'subtype',
   *   scope: 'the scope for the event (one per telemetry system)',
   *   payload: {
   *     param: 'any data required to construct a specific GCT event'
   *   }
   * });
   */
  addEvent(event) {
    if (!event) return;
    const url = encryptOnlineIdInUrl(window.location.href);
    const { payload = {} } = event;
    const location = this.#locator.resolveLocation(payload.location);
    const eventWithCommonPayload = {
      ...event,
      payload: {
        ...payload,
        time: new Date(),
        url,
        ...location,
      },
    };

    this.#notifyListeners(eventWithCommonPayload);

    if (this.#reporter) {
      this.#processEvent(eventWithCommonPayload);
    } else {
      this.#events.push(eventWithCommonPayload);
    }
  }

  #notifyListeners = (event) => {
    const notifyListener = this.#callbacks[event.type];

    if (typeof notifyListener === 'function') {
      notifyListener(event);
    }
  };

  #processEvent = event => (this.#reporter.dispatch(event)
    .then(() => this.#removeStoredEvent(event))
    .then(() => {
      if (typeof event.afterSent === 'function') {
        event.afterSent();
      }
    }))

  #removeStoredEvent = (event) => {
    const index = this.#events.indexOf(event);

    return this.#events.splice(index, 1);
  };

  /**
   * Register a callback to be called when an event of a certain type occurs.
   * NOTE: Current limit is one callback per event type
   * @param {string} eventType - the type of event to be notified about
   * @param {Function} callback - the function to call
   *
   * @example
   * buffer.on('UverEvent', console.log);
   */
  on(eventType, callback) {
    this.#callbacks[eventType] = callback;
  }

  /**
   * Remove the registered callback for a certain event type.
   * @param {string} eventType - the type of event to be notified about
   *
   * @example
   * buffer.off('UverEvent');
   */
  off(eventType) {
    delete this.#callbacks[eventType];
  }
}
