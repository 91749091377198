import buildPdcSearchUrl from './buildPdcSearchUrl';
import getQueryParam from '../../../../utils/getQueryParam';

const makeSMCID = ({ location, searchTerm }) => `${location}:${searchTerm}`;

export default class PdcSearch {
  /** configuration settings */
  #config = {};

  /** used to uniquely identify this search strategy */
  static name = 'pdc';

  constructor(config) {
    this.#config = config;
    this.name = PdcSearch.name;
  }

  /**
   * @param {string} searchTerm - search string
   */
  search = searchTerm => Promise.resolve({
    type: 'linkOnly',
    results: [],
    seeAllUrl: this.getUrl(searchTerm),
    timestamp: +(new Date()),
  });

  /**
   * Builds a PDC search destination for a given URL.
   * @param {string} searchTerm - search term string
   * @returns {string} PDC search results page
   */
  getUrl = searchTerm => buildPdcSearchUrl({
    ...this.#config,
    searchTerm,
    smcid: getQueryParam(window.location.search, 'smcid') || makeSMCID({
      location: this.#config.location,
      searchTerm,
    }),
  });
}
