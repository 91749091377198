import regions from './regions';
import generateElementId from './utils/generateElementId';
import l10n, { determineLocale, parseLocale, storeToPdcMapping } from './utils/l10n';
import header, { anchor, sonyBar } from './header';
import createElementFromHTML from './utils/createElementFromHTML';
import createSkipLink from './createSkipLink';
import isRtlLanguageLocale from './utils/isRtlLanguageLocale';
import initSticky from './utils/sticky';
import { clickAway } from './utils/ClickAwayContext';
import keyCodes from './utils/keyboardEventCodes';
import { getGlobalKeydownHandler } from './utils/GlobalKeydownHandler';
import { setFocusRingHandler } from './utils/FocusRingHandler';
import { closingSubNav, switchingBetweenTabs } from './utils/subNavAnimationHelpers';
import createTertiaryNavHandlers from './createTertiaryNavHandlers';
import initTelemetry from './initTelemetry';
import addQuickLinksTelemetryData from './addQuickLinksTelemetryData';
import getWebToolbarScript from './utils/getWebToolbarScript';

const noop = () => {};

const getBrowserWidth = (window) => {
  const { document } = window;
  const bodyEl = document.getElementsByTagName('body')[0];

  return window.innerWidth || document.documentElement.clientWidth || bodyEl.clientWidth;
};

const buildNavContainer = ({
  document,
  wnd = {},
  navData,
  locale,
  sharedNav,
  isSSR,
}) => {
  const opts = (wnd.sharedNav && wnd.sharedNav.opts) || {};
  const navContainer = createElementFromHTML(header(locale, isSSR, opts), document);

  if (!opts.barebones) {
    const primaryNav = sharedNav.renderNavMenu(navData.primaryNav, 'primary');
    navContainer.querySelector('.shared-nav__primary-parent').appendChild(primaryNav);
  }

  return navContainer;
};

const retrieveSSRSharedNavContainer = document => (
  document.querySelector('[data-jetstream-ssr-nav-container]')
);

const wasNavContainerServerRendered = navContainer => navContainer.dataset.jetstreamSsrNavContainer;

const SHARED_NAV_CONTAINER = 'shared-nav-container';
const SHARED_NAV_SELECTOR = '#shared-nav';
const SHARED_NAV_MENUS = 'nav.shared-nav';
const SB_SOCIAL_TOOLBAR_ROOT = '#sb-social-toolbar-root';
const SHARED_NAV_CTAS_CONTAINER = '.shared-nav__ctas-container';
const DEFAULT_SEARCH_CONTEXT = 'pdc';
const FALLBACK_LOCALE = 'en-us';

/**
 * The social toolbar root of starblaster can be one of two values `#sb-social-toolbar-root` or
 * `#sb-social-toolbar-root-placeholder`. The placeholder is used when starblaster toolbar root has
 * to be placed outside of the insertion point expected by Jetstream (as is the case for VSF
 * [web store])
 * @private
 * @param {HTMLElement} searchFromEl - element to begin searching for social toolbar root
 * @return {HTMLElement} socialToolBarRootEl - the social toolbar root or placeholder for social
 *  toolbar root
 */
const getSocialToolbarRootEl = searchFromEl => searchFromEl.querySelector(`${SB_SOCIAL_TOOLBAR_ROOT}-placeholder`)
  || searchFromEl.querySelector(SB_SOCIAL_TOOLBAR_ROOT);

/**
 * Kamaji constant for unknown age
 */
const STORE_SEARCH_AGE_UNKNOWN = 99;

export const getSharedNavEl = document => (
  document.querySelector(SHARED_NAV_SELECTOR)
);

export default ({
  wnd = window,
  isSSR = false,
  initSbSkeleton,
} = {}) => {
  const { document } = wnd;
  const opts = (wnd.sharedNav && wnd.sharedNav.opts) || {};
  const locale = determineLocale(wnd);
  const navData = regions[locale] || regions[storeToPdcMapping[locale]] || regions[FALLBACK_LOCALE];
  const getLocalizedString = l10n(locale);

  if (!navData) {
    throw Error(
      `Missing regionalData for locale "${locale}" `
        + `and fallback locale ${FALLBACK_LOCALE}`,
    );
  }

  const sharedNavEl = getSharedNavEl(document);
  if (!sharedNavEl) {
    throw Error(`Missing ${SHARED_NAV_SELECTOR} container`);
  }

  let showSearchContextSelector = !opts.disablePDCSearch
    && !navData.disablePDCSearch
    && !navData.disableStoreSearch;
  let storeSearchUserAge = STORE_SEARCH_AGE_UNKNOWN;
  let searchSource = DEFAULT_SEARCH_CONTEXT;
  let searchLine = process.env.NODE_ENV === 'development' ? 'e1-np' : 'np';
  let onStoreLinkClick;

  /**
   * A promise which resolves when either
   * 1) The Jetstream search module loads asynchronously, or
   * 2) We're in SSR, and we don't need the search module.
   * @type {Promise<object | null>}
   */
  const loadSearch = new Promise((resolve, reject) => {
    if (!isSSR) {
      // deferred to allow the implementor the opportunity to
      // set the webpack public url
      setTimeout(() => import('./search').then(resolve, reject));
      return;
    }

    resolve({ default: noop });
  });

  let telemetry;
  let webToolbarType;

  return {
    version: process.env.JET_VERSION,
    init() {
      const navContainer = retrieveSSRSharedNavContainer(document) || buildNavContainer({
        isSSR,
        wnd,
        document,
        navData,
        locale,
        sharedNav: this,
      });

      webToolbarType = opts.webToolbarType || 'starblaster';

      sonyBar(navData.sonyBar.link, { document });

      if (opts.disablePDCSearch || navData.disablePDCSearch) {
        this.setDefaultSearchSource('store');
      }

      if (this.hasTertiaryNav() || isSSR) {
        const outermostSharedNavContainer = document.getElementById(SHARED_NAV_CONTAINER);

        if (outermostSharedNavContainer) {
          outermostSharedNavContainer.classList.add('shared-nav-container--minimized');
        }
      }

      if (isSSR || !wasNavContainerServerRendered(navContainer)) {
        const sharedNavMenus = navContainer.querySelector(SHARED_NAV_MENUS);
        const ctasContainer = navContainer.querySelector(SHARED_NAV_CTAS_CONTAINER);
        const socialToolbarRoot = getSocialToolbarRootEl(sharedNavEl);

        /*
         * The order of these appends matters. They ensure proper tab order for
         * the elements in primary nav
         */
        sharedNavEl.classList.add('shared-nav--ssr');
        sharedNavEl.appendChild(sharedNavMenus);
        sharedNavEl.appendChild(socialToolbarRoot);
        sharedNavEl.appendChild(ctasContainer);
      }

      if (!isSSR) {
        this.hydrate(sharedNavEl.querySelector('.shared-nav-list'));
      }

      if (webToolbarType === 'starblaster' && typeof initSbSkeleton === 'function') {
        initSbSkeleton();
      }

      if (!isSSR) {
        this.loadWebToolbarScript(window.location.hostname);
      }
    },

    loadWebToolbarScript(hostname) {
      const scriptToLoad = getWebToolbarScript(hostname, webToolbarType);

      if (scriptToLoad) {
        const {
          crossorigin,
          id,
          src,
        } = scriptToLoad;
        const script = document.createElement('script');

        script.id = id;
        script.src = src;
        script.crossorigin = crossorigin;

        document.body.append(script);
      }
    },

    hasTertiaryNav() {
      return document.getElementById('jetstream-tertiary-nav');
    },

    buildSkipLink() {
      const { buildSkipLink = true, skipToAnchor = 'main' } = opts;
      createSkipLink(locale, document, buildSkipLink, skipToAnchor);
    },

    hydrate(sharedNavList) {
      this.pruneNav();
      this.buildSkipLink();
      this.addClickListeners();
      this.addButtonExpandStateHandler();
      this.addSearchClickListener();
      this.addLocalizationMetadataToHtml();
      this.addClickAwayHandler();
      this.addAutoL2CloseOnEsc();
      createTertiaryNavHandlers(wnd);
      setFocusRingHandler(sharedNavEl);
      telemetry = initTelemetry(webToolbarType);
      this.addQuickLinks(sharedNavList, locale, () => { addQuickLinksTelemetryData(telemetry); });
    },

    addButtonExpandStateHandler() {
      const primaryButtons = sharedNavEl.querySelectorAll('button.shared-nav__primary-button');

      primaryButtons.forEach((button) => {
        this.addMenuButtonAccessibility(button);
      });
    },

    addClickAwayHandler() {
      const clickAwayUtil = clickAway();
      clickAwayUtil.add({
        node: sharedNavEl,
        onClickAway: this.autoCloseActiveSubMenu.bind(this),
      });
    },

    addAutoL2CloseOnEsc() {
      const globalKeydownHandler = getGlobalKeydownHandler();
      globalKeydownHandler.addKeydownHandler(keyCodes.ESCAPE,
        this.autoCloseActiveSubMenu.bind(this));
    },

    setOnStoreLinkClick(callback) {
      onStoreLinkClick = callback;
    },

    /**
     * Indicates if the search context select should be visible
     * When true, a selector is available to toggle between PDC and Store
     * @param {boolean} show
     */
    setShowSearchContextSelector(show) {
      showSearchContextSelector = show;
    },

    /**
     * Sets the default selected search source
     * @param {string} source - possible values "store" or "pdc"
     */
    setDefaultSearchSource(source) {
      searchSource = source;
    },

    /**
     * Sets the user's calculated age for searching the store.
     * This value should come from one of our account APIs.
     * @param {number} age - the user's age
     */
    setStoreSearchUserAge(age) {
      storeSearchUserAge = age;
    },

    /**
     * Sets the line environment that we should be performing search against
     * @param {string} line - the line ('e1-np', 'mgmt', etc.)
     */
    setSearchLine(line) {
      searchLine = line;
    },

    autoCloseActiveSubMenu() {
      const activeNavElement = sharedNavEl.querySelector('.active');

      if (activeNavElement) {
        const targetParent = activeNavElement.parentElement;
        const sharedNavParent = sharedNavEl.querySelector('.shared-nav');
        const activeNavElementMenuBtn = activeNavElement.querySelector('.shared-nav__primary-button');
        const theRealSharedNavContainer = document.querySelector('#shared-nav-container');

        activeNavElement.classList.remove('active');
        sharedNavParent.classList.remove('shared-nav--menu-open');
        sharedNavParent.classList.add('shared-nav--menu-closed');
        targetParent.classList.remove('shared-nav--list-open');
        theRealSharedNavContainer.classList.remove('shared-nav-container--menu-open');
        this.setMenuButtonExpandState(activeNavElementMenuBtn, false);
        closingSubNav(activeNavElement);
      }
    },

    addLocalizationMetadataToHtml() {
      const htmlElement = document.querySelector('html');
      const dir = htmlElement.getAttribute('dir');
      const isRtl = locale ? isRtlLanguageLocale(locale) : false;

      if (dir === 'ltr' || dir === 'rtl') {
        return;
      }

      htmlElement.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
    },

    updatePlatformPrivacyLevel(privacyLevel) {
      const { sharedNav, sbWeb } = wnd;

      if (sharedNav.telemetry) {
        sharedNav.telemetry.platformPrivacyLevel = privacyLevel;
      }

      if (typeof sbWeb.updatePlatformPrivacyLevel === 'function') {
        sbWeb.updatePlatformPrivacyLevel(privacyLevel);
      }
    },

    /**
     * Creates a sticky handler for the navigation
     * @returns {Function}
     */
    initStickiness() {
      const sticky = initSticky();

      return {
        attachElement: el => this.stickyElement.attach(el),
        makeSticky: (el) => {
          if (typeof this.removeStickiness === 'function') {
            this.removeStickiness();
          }

          const sonyBarEl = document.getElementById('sony-bar');
          const sharedNavContainerEl = document.getElementById('shared-nav-container');
          const hasPullsElement = el && el !== sharedNavContainerEl;
          const options = {
            pullsElement: hasPullsElement ? sharedNavContainerEl : null,
            offset: () => (getBrowserWidth(window) <= 1024 ? 0 : sonyBarEl.offsetHeight),
          };

          const baseStickyElement = el || sharedNavContainerEl;
          const { stickyElement, removeStickiness } = sticky.makeSticky(baseStickyElement, options);

          this.stickyElement = stickyElement;
          this.removeStickiness = removeStickiness;

          return this.stickyElement;
        },
      };
    },

    renderButton(buttonInfo, navType) {
      const listButton = document.createElement('button');

      const classNames = [
        `shared-nav__${navType}-button`,
        'shared-nav-button',
        'shared-nav-icon',
        'shared-nav-icon--chevron-after',
        'dtm-no-track',
      ];

      if (buttonInfo.mobileNavIcon) {
        classNames.push('shared-nav-mobile-icon');
        classNames.push(`shared-nav-icon--${buttonInfo.mobileNavIcon}`);
      }

      // IE11 does not support adding of multiple classes
      listButton.className = classNames.join(' ');

      if (buttonInfo.mobileLabel) {
        listButton.innerHTML += `<span class='shared-nav__compact-label'>${buttonInfo.mobilelabel}</span>`;
        listButton.innerHTML += `<span class='shared-nav__full-label'>${buttonInfo.label}</span>`;
      } else {
        listButton.textContent = buttonInfo.label;
      }

      listButton.id = generateElementId(`menu-button-${navType}`, buttonInfo.stringId);

      return listButton;
    },

    setMenuButtonExpandState(menuButton, isExpanded) {
      menuButton.setAttribute('aria-expanded', isExpanded);
    },

    /**
     * Add aria-haspopup and aria-expanded attributes for menu button to enhance the
     * accessibility of the menu button.
     * aria-haspopup indicates the availability of interactive popup element that will
     * open up on click on button.
     * aria-expanded indicates the collapse/expanded state of the primary nav (hamburger)
     * or tertiary navs (primary nav)
     * @param {Object} menuButton - DOM representation of the menu button
     */
    addMenuButtonArias(menuButton) {
      menuButton.setAttribute('aria-haspopup', true);
      this.setMenuButtonExpandState(menuButton, false);
    },

    addMenuButtonAccessibility(menuButton) {
      this.addMenuButtonArias(menuButton);
      menuButton.addEventListener('click', () => {
        const isExpanded = menuButton.getAttribute('aria-expanded');
        const expandedMenuButton = this.getExpandedMenuButton();

        if (expandedMenuButton && expandedMenuButton !== menuButton) {
          this.setMenuButtonExpandState(expandedMenuButton, false);
        }
        this.setMenuButtonExpandState(menuButton, isExpanded !== 'true');

        return true;
      });
    },

    getExpandedMenuButton() {
      return sharedNavEl.querySelector('button.shared-nav__primary-button[aria-expanded="true"]');
    },

    /**
     * Fetches a quicklinks JSON config for a locale and populates the nav based on that data.
     *
     * @param {HTMLElement} container - HTMLElement for shared nav; Used to find child elements
     *                                  to attach a link list (quicklinks) to.
     * @param {String} localeCode - value representing the language/country code combination
     *                              for a given locale.
     */
    addQuickLinks(sharedNavList, localeCode, callback) {
      const linkDataUrl = `https://social.playstation.com/jetstream/quicklinks/${encodeURIComponent(localeCode)}.json`;
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;

        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const linkData = JSON.parse(xhr.responseText);

            if (!linkData) return;

            Object.keys(linkData).forEach((section) => {
              /*
                Assign `querySelector()` in case result is `null` to test truthiness below.
                Quicklink configs in shared-nav-ia may try to append to non-existant L1 DOM node.
              */
              const listContainer = sharedNavList.querySelector(
                `.shared-nav__secondary-parent--${section} .shared-nav-list`,
              );

              if (
                linkData[section]
                  && linkData[section].length > 0
                  && listContainer
              ) {
                const quickLinks = this.renderQuicklinksContainer({
                  linkListData: linkData[section],
                  section,
                });

                const listItemContainer = document.createElement('div');

                listItemContainer.className = 'shared-nav__secondary-item link-list-separator';
                listItemContainer.appendChild(quickLinks);
                listContainer.appendChild(listItemContainer);
              }
            });

            if (typeof callback === 'function') {
              callback();
            }
          } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            console.warn(`Bad quicklinks file at ${linkDataUrl}`);
          }
        }
      };

      xhr.open('GET', linkDataUrl);
      xhr.send();
    },

    renderQuicklinksContainer(quickLinks) {
      const linkListContainerHTMLStr = `
        <div class="link-list-container">
          ${this.renderQuicklinks(quickLinks).outerHTML}
        </div>
      `;

      return createElementFromHTML(linkListContainerHTMLStr, document);
    },

    renderQuicklinks({ linkListData, section }) {
      const listContainer = document.createElement('section');
      listContainer.setAttribute('aria-label', getLocalizedString('msg_sr_quicklinks_menu'));

      listContainer.classList.add('shared-nav__link-list');

      linkListData.forEach((linkListItem, index) => {
        const listItemContainer = document.createElement('div');
        const listItemId = `${section}_${index}`;

        listItemContainer.classList.add('link-list-item');
        listItemContainer.appendChild(createElementFromHTML(
          anchor(wnd, {
            locale,
            baseUrl: navData.baseUrl,
            anchorData: linkListItem,
            section,
            type: 'link-list',
            indexedId: listItemId,
          }),
          document,
        ));
        listContainer.appendChild(listItemContainer);
      });

      return listContainer;
    },

    renderNavMenu(items, navType, section) {
      const listContainer = document.createElement('section');
      listContainer.setAttribute('aria-label', getLocalizedString('msg_sr_menu_level_two'));

      listContainer.className = [`shared-nav__${navType}`, 'shared-nav-list'].join(' ');

      items.forEach((item) => {
        const listItemContainer = document.createElement('div');

        listItemContainer.classList.add(`shared-nav__${navType}-item`);
        if (item.subNav) {
          listItemContainer.appendChild(this.renderButton(item, navType));
          listItemContainer.appendChild(this.renderSubNav(item));
        } else if (item.link) {
          listItemContainer.appendChild(
            createElementFromHTML(
              anchor(wnd, {
                locale,
                baseUrl: navData.baseUrl,
                anchorData: item,
                section,
                type: navType,
              }),
              document,
            ),
          );
        }

        listContainer.appendChild(listItemContainer);
      });

      return listContainer;
    },

    renderSubNav(navItem) {
      // This container exists to render the container properly
      // with fixed positions and flex box.
      const subNavHTMLStr = `
        <div class="shared-nav__secondary-parent shared-nav__secondary-parent--${navItem.stringId}">
          <div class="shared-nav__secondary-container">
            <h3 id=${generateElementId('shared-nav__secondary-header', navItem.stringId)} class="shared-nav__secondary-header">
              <button class="shared-nav__secondary-header-toggle shared-nav-button shared-nav-icon shared-nav-icon--hamburger">
                ${navItem.label}
              </button>
            </h3>
            ${this.renderNavMenu(navItem.subNav, 'secondary', navItem.stringId).outerHTML}
          </div>
        </div>
      `;

      return createElementFromHTML(subNavHTMLStr, document);
    },

    toggleHamburgerMenu(event) {
      const activeNavElement = sharedNavEl.querySelector('.open') || {};
      const activeNavElementClassList = activeNavElement.classList;
      const targetElement = event.target;
      const targetClassList = targetElement.classList;
      const sharedNavRoot = document.querySelector('#shared-nav-root');
      const BODY_NOSCROLL = 'jetstream-body-noscroll';

      if (!targetClassList) {
        return;
      }

      if (targetClassList.contains('shared-nav-hamburger')) {
        // Clicking on hamburger button when it is on open state will close the L1
        if (targetClassList.contains('shared-nav-hamburger--open')) {
          targetClassList.remove('shared-nav-hamburger--open');
          sharedNavRoot.classList.remove('shared-nav--mobile-open');
          this.setMenuButtonExpandState(targetElement, false);

          document.documentElement.classList.remove(BODY_NOSCROLL);
          document.body.classList.remove(BODY_NOSCROLL);
        } else {
          // Clicking on hamburger button when it is on off state will open the L1
          targetClassList.add('shared-nav-hamburger--open');
          sharedNavRoot.classList.add('shared-nav--mobile-open');
          this.setMenuButtonExpandState(targetElement, true);

          document.documentElement.classList.add(BODY_NOSCROLL);
          document.body.classList.add(BODY_NOSCROLL);
        }

        // Close active parent element(s)
        if (activeNavElementClassList) {
          sharedNavEl.querySelectorAll('.shared-nav__primary-parent.open')
            .forEach(el => el.classList.remove('open'));
        } else {
          // Open active parent element(s)
          sharedNavEl.querySelectorAll('.shared-nav__primary-parent')
            .forEach(el => el.classList.add('open'));
        }
      } else if (targetClassList.contains('shared-nav__secondary-header-toggle')) {
        // Clicking on hamburger sideway button will close the secondary parent
        this.autoCloseActiveSubMenu();
      }
    },

    toggleActiveMenuItem(event) {
      const activeNavElement = sharedNavEl.querySelector('.active');
      const targetParent = event.target.parentElement;
      const sharedNavParent = sharedNavEl.querySelector('.shared-nav');
      const theRealSharedNavContainer = document.querySelector('#shared-nav-container');

      if (!targetParent) {
        return;
      }

      if (targetParent.classList.contains('shared-nav__primary-item')) {
        if (targetParent.classList.contains('active')) {
          // toggle/remove active L1 element
          sharedNavParent.classList.remove('shared-nav--menu-open');
          sharedNavParent.classList.add('shared-nav--menu-closed');
          theRealSharedNavContainer.classList.remove('shared-nav-container--menu-open');
          targetParent.classList.remove('active');
          targetParent.parentElement.classList.remove('shared-nav--list-open');

          closingSubNav(targetParent);
        } else {
          if (activeNavElement) {
            // switch between L1s, remove current active L1
            activeNavElement.classList.remove('active');
            sharedNavParent.classList.remove('shared-nav--menu-open');
            sharedNavParent.classList.add('shared-nav--menu-closed');

            const oldSecondaryParent = activeNavElement.querySelector('.shared-nav__secondary-parent');
            const newSecondaryParent = targetParent.querySelector('.shared-nav__secondary-parent');
            switchingBetweenTabs(oldSecondaryParent, newSecondaryParent);
          }
          // make the clicked L1 item an active one
          targetParent.classList.add('active');
          sharedNavParent.classList.add('shared-nav--menu-open');
          sharedNavParent.classList.remove('shared-nav--menu-closed');
          targetParent.parentElement.classList.add('shared-nav--list-open');
          theRealSharedNavContainer.classList.add('shared-nav-container--menu-open');
        }
      }
    },

    removeNode(nodeToRemove) {
      if (nodeToRemove) {
        nodeToRemove.parentNode.removeChild(nodeToRemove);
      }
    },

    pruneNav() {
      const {
        barebones,
        disableLogoLink,
      } = opts;

      const ssrNavContainer = document.querySelector('.shared-nav--ssr');

      if (ssrNavContainer) {
        const primaryNav = document.querySelector('.shared-nav__primary-parent');
        if (primaryNav && barebones) {
          this.removeNode(primaryNav);
          this.removeNode(document.querySelector('.shared-nav-hamburger'));
        }

        const logoLink = document.querySelector('.shared-nav-ps-logo-link');
        if (logoLink && disableLogoLink) {
          const logoContainer = document.querySelector('.shared-nav__ps-logo-container');
          const logo = document.querySelector('.shared-nav-ps-logo');

          this.removeNode(logoLink);
          logoContainer.appendChild(logo);
        }

        ssrNavContainer.classList.remove('shared-nav--ssr');
      }
    },

    addClickListeners() {
      sharedNavEl.onclick = (event) => {
        this.toggleHamburgerMenu(event);
        this.toggleActiveMenuItem(event);
      };
    },

    addSearchClickListener() {
      if (!opts.disableSearch) {
        const sharedNavSearchButton = sharedNavEl.querySelector('.shared-nav-icon--search');
        sharedNavSearchButton.onclick = () => {
          window.performance.mark('search:open');
          this.initSearch(sharedNavSearchButton);
        };
      }
    },

    initSearch(returnEl) {
      this.autoCloseActiveSubMenu();

      loadSearch.then(({ default: mountSearch }) => {
        const { language, script, country } = parseLocale(locale);

        const options = {
          returnEl,
          language,
          script,
          country,
          showSearchContextSelector,
          searchSource,
          age: storeSearchUserAge,
          line: searchLine,
          l10n: l10n(locale),
          telemetry,
          onStoreLinkClick,
        };

        mountSearch(options);
      });
    },
  };
};
