import StickyElement, { getScrollY } from './StickyElement';

/**
 * Responsible for orchestrating all sticky elements in accordance with user
 * scrolling
 */
export default class Sticky {
  /**
   * @type {Array.<StickyElement>} - the sticky elements
   */
  #elements = [];

  /**
   * @type {Number} - the last captured scroll position
   */
  #previousScrollY = 0;

  constructor() {
    this.#previousScrollY = getScrollY();
  }

  #isScrollUp = () => this.#previousScrollY > getScrollY();

  handleScroll = () => {
    this.#elements.forEach((e) => {
      if (e.shouldStick()) {
        e.stick();
      } else {
        e.unStick();
      }

      if (this.#isScrollUp()) {
        e.pull();
      } else {
        e.push();
      }
    });

    this.#previousScrollY = getScrollY();
  }

  init() {
    window.addEventListener('scroll', this.handleScroll);

    return this;
  }

  makeSticky(element, { threshold, pullsElement, offset } = {}) {
    const stickyElement = new StickyElement({
      element,
      threshold,
      pullsElement,
      offset,
    });

    this.#elements.push(stickyElement);

    return {
      stickyElement,
      removeStickiness: () => {
        this.#elements = this.#elements.filter((x) => {
          if (x === stickyElement) {
            stickyElement.forceUnstick();
            return false;
          }
          return true;
        });
      },
    };
  }
}
